import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Iconify from '../components/iconify/Iconify';
import config from '../config';
import { UserContext } from '../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { fetchDataWithAuth, postData } from '../utils/api';
import { fNumber } from '../utils/formatNumber';
import { ToastError, formatDate, formatDateTimeUTC, formatNumber, showCurrency } from '../utils/helper';
import BetDetailDialog from './BetDetailDialog';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
const schema = yup.object().shape({
  // type: yup.number(),
  // pbet_uid: yup
  //   .string()
  //   .matches(/^[^\s].*/, 'starting spaced is not allowed')
  //   .matches(/^[0-9]+$/, 'Only numbers are allowed')
  //   .length(10, 'Must be exactly 10 digits'),
});

const BettingReport = () => {
  const REACT_APP_API_URL = config.apiUrl.slice(0, -4);

  const [betReportList, setBetReportList] = useState([]);
  const [extra_data, setExtraData] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState([]);
  const [BetDetail, setBetDetail] = useState(null);
  const [BetBarcode, setBetBarcode] = useState(null);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectVal, setSelectVal] = useState(1);
  const [selectUser, setSelectUser] = useState('');
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [BetId, setBetId] = useState('');
  const [sortData, setSortData] = useState('DESC');
  const [sortBy, setSortBY] = useState('');
  const [loading, SetLoading] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [rangeValues, setRangeValues] = useState([10, 5000]);
  const [isSettled, setIsSettled] = useState(false);

  const { user } = useContext(UserContext);

  const handleRangeChange = (event, newValue) => {
    setRangeValues(newValue);
    setValue('start_price', newValue[0]);
    setValue('end_price', newValue[1]);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchBetReport = async (sortBy, sortData, page, rowsPerPage) => {
    const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
    const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    
    try {
      const match_type = [];
      if (filterData.Live === true) {
        match_type.push(1);
      }
      if (filterData.PreMatch === true) {
        match_type.push(0);
      }

      if(match_type.length === 0) {
        match_type.push(0);
      }

      const data = {
        user_token: user.token,
        data: {
          user_id: selectUser !== '' ? selectUser : '',
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData !== '' ? sortData : '',
          pbet_uid: filterData.pbet_uid ? filterData.pbet_uid : '',
          bet_place_type: filterData.bet_place_type ? filterData.bet_place_type : '',
          match_type,
          playerbet_status: filterData.playerbet_status ? filterData.playerbet_status : '',
          start_date: selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
          end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
          start_price: filterData.start_price ? filterData.start_price : '',
          end_price: filterData.end_price ? filterData.end_price : '',
          isSettled,
        },
      };
      SetLoading(true);
      const response = await postData(`/${user.type}/bet/report?page=${page + 1}&size=${rowsPerPage}`, data);
      setBetReportList(response.data);
      setExtraData(response.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
    }
  };
  const getUsers = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/${user.type}/user/list`, data);
    const userData = response.data;
    setUsers(userData);
  };

  useEffect(() => {
    getUsers();
  }, [user]);
  useEffect(() => {
    fetchBetReport(sortBy, '', page, rowsPerPage, '');
  }, [setValue]);

  const Type = [
    {
      id: '',
      label: 'Select Type',
    },
    {
      id: 1,
      label: 'Single',
    },
    {
      id: 2,
      label: 'Combi',
    },
  ];
  const Status = [
    {
      id: 0,
      label: 'Not Settled',
    },
    {
      id: 1,
      label: 'Lost',
    },
    {
      id: 2,
      label: 'Win',
    },
    {
      id: 3,
      label: 'Refund',
    },
  ];

  const onSearch = async (betFilter) => {
    const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
    const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    setPage(0);
    setFilterData(betFilter);

    const match_type = [];
    if (betFilter.Live === true) {
      match_type.push(1);
    }
    if (betFilter.PreMatch === true) {
      match_type.push(0);
    }
    if(match_type.length === 0) {
      match_type.push(0);
    }
    // if (
    //   betFilter.user_id ||
    //   betFilter.playerbet_status ||
    //   betFilter.bet_place_type ||
    //   betFilter.pbet_uid ||
    //   betFilter.end_date ||
    //   betFilter.start_date ||
    //   betFilter.start_price ||
    //   betFilter.end_price ||
    //   betFilter.match_type ||
    //   betFilter.isSettled
    // ) {
      const data = {
        user_token: user.token,
        data: {
          pbet_uid: betFilter.pbet_uid ? betFilter.pbet_uid : '',
          bet_place_type: betFilter.bet_place_type ? betFilter.bet_place_type : '',
          match_type,
          playerbet_status: betFilter.playerbet_status ? betFilter.playerbet_status : '',
          user_id: betFilter.user_id ? betFilter.user_id : '',
          start_date:  selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
          end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
          start_price: betFilter.start_price ? betFilter.start_price : '',
          end_price: betFilter.end_price ? betFilter.end_price : '',
          isSettled,
          // order_key: sortBy !== '' ? sortBy : '',
          // order_value: sortData,
        },
      };
      toast.dismiss();
      try {
        SetLoading(true);
        const response = await postData(`/${user.type}/bet/report?page=${1}&size=${rowsPerPage}`, data);
        setFilter(data.data);
        setBetReportList(response.data);
        setExtraData(response.extra_data);
        SetLoading(false);
      } catch (error) {
        ToastError(error.response.data.message);
      }
    // }
  };

  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] !== null ? newValue[0] : '', newValue[1] !== null ? newValue[1] : '']);
    // if (newValue[0] && newValue[1]) {
    //   const start_date = new Date(newValue[0]);
    //   const endDate = new Date(newValue[1]);
    //   const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    //   const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    //   setValue('start_date', utcstartDate);
    //   setValue('end_date', utcEndDate);
    // }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchBetReport(sortBy, sortBy ? sortData : '', newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchBetReport(sortBy, sortBy ? sortData : '', 0, event.target.value);
  };

  const getWinLose = (status) => {
    switch (status) {
      case 0:
        return '0-0-0';
      case 1:
        return '0-1-0';
      case 2:
        return '1-0-0';
      case 3:
        return '0-0-1';
      default:
        return '0-0-0';
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return <span className="status running" />;
      case 1:
        return <span className="status lost" />;
      case 2:
        return <span className="status win" />;
      case 3:
        return <span className="status cancelled" />;
      default:
        return <span className="status running" />;
    }
  };

  const handleOpenDetails = async (pbet_uid) => {
    const data = {
      user_token: user.token,
      data: {
        pbet_uid: pbet_uid.replace(`${config.placeBetShortName}`, ''),
      },
    };
    toast.dismiss();
    try {
      const response = await postData(`/${user.type}/get/bet/details`, data);
      const betData = response;
      setOpen(true);
      setBetDetail(betData.data[0]);
      setBetBarcode(betData.data[0].pbet_url);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setBetDetail(null);
    setBetBarcode(null);
  };
  // export start
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const saveBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const handleFileTypeDownload = async (filetype) => {
    const startDate = new Date(selectedDateRange[0] !== '' ? selectedDateRange[0] : '');
    const endDate = new Date(selectedDateRange[1] !== '' ? selectedDateRange[1] : '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

    setDisable(true);
    const match_type = [];
    if (filterData.Live === true) {
      match_type.push(1);
    }
    if (filterData.PreMatch === true) {
      match_type.push(0);
    }

    if(filterData.isSettled !== '0') {
      setIsSettled(true);
    }
    const data = {
      user_token: user.token,
      data: {
        page: selectVal === 2 ? page + 1 : '',
        row_per_page: selectVal === 2 ? rowsPerPage : '',
        file_type: filetype,
        order_key: sortBy !== '' ? sortBy : '',
        order_value: sortData,
        pbet_uid: BetId,
        bet_place_type: filterData.bet_place_type,
        match_type:match_type.length ? match_type : [0],
        playerbet_status: filterData.playerbet_status,
        user_id: filterData.user_id,
        start_date: selectedDateRange[0] === '' ? '' : formatDate(utcstartDate, 'datepicker'),
        end_date: selectedDateRange[1] === '' ? '' : formatDate(utcEndDate, 'datepicker'),
        start_price: filterData.start_price,
        end_price: filterData.end_price,
        isSettled,
        headers: ['Bet ID', 'Date', 'Shop', 'User', 'Type', 'Amount', 'Possible Win', 'Win', 'Win-Lost-Void', 'Status'],
      },
    };
    try {
      const BettingReport = await postData(`/${user.type}/bet/report/export`, data);
      let mimeType;
      let fileExtension;

      if (filetype === 2) {
        mimeType = 'text/csv';
        fileExtension = '.csv';
      } else if (filetype === 3) {
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        fileExtension = '.xlsx';
      } else if (filetype === 1) {
        mimeType = 'application/json';
        fileExtension = '.json';
      } else {
        // Handle unsupported file types

        return;
      }

      const blob = await fetch(`${REACT_APP_API_URL}${BettingReport.data[0].file_path}`).then((response) =>
        response.blob()
      );

      const filename = `BettingReport${fileExtension}`;
      saveBlob(blob, filename);
      setDisable(false);
    } catch (error) {
      console.log('error :>> ', error);
      setDisable(false);
    }
    setAnchorEl(null);
  };
  const handleSelectChange = (event) => {
    setSelectVal(event.target.value);
  };
  // export end

  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchBetReport(sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
  };

  const clearFilter = async () => {
    reset();
    setSelectedDateRange(['', '']);
    setRangeValues([10, 5000]);
    setSelectUser('');
    setStatus('');
    setType('');
    setPage(0);
    setSelectVal(1);
    setFilterData([])
    setBetId('');
    setIsSettled(false);
    // if (
    //   selectUser !== '' ||
    //   status !== '' ||
    //   type !== '' ||
    //   BetId !== '' ||
    //   selectedDateRange[0] !== '' ||
    //   selectedDateRange[1] !== ''
    // ) {
      const data = {
        user_token: user.token,
        data: {
          pbet_uid: '',
          bet_place_type: '',
          match_type: [0],
          playerbet_status: '',
          user_id: '',
          start_date: '',
          end_date: '',
          start_price: '',
          end_price: '',
          isSettled :false,
        },
      };
      SetLoading(true);
      const response = await postData(`/${user.type}/bet/report?page=${1}&size=${rowsPerPage}`, data);
      setFilter(filter);
      setBetReportList(response.data);
      setExtraData(response.extra_data);
      SetLoading(false);
    // }
  };
  const onchangeUser = (event) => {
    setSelectUser(event.target.value);
  };
  const onchangeStatus = (event) => {
    setStatus(event.target.value);
  };
  const onchangeType = (event) => {
    setType(event.target.value);
  };
  const isNotFound = !betReportList.length;

  const onchangeBetId = (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, '');
    setBetId(sanitizedValue);
  };
  const min_date = new Date();
  const minDate = dayjs(min_date);
  return (
    <>
      <Helmet>
        <title> Betting Report | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      {BetDetail ? (
        <BetDetailDialog
          setOpen={setOpen}
          open={open}
          handleClose={handleClose}
          BetDetail={BetDetail}
          BetBarcode={BetBarcode}
        />
      ) : (
        <></>
      )}
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="mdi:file-report-outline" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Betting Report
            </Typography>
          </Box>
        </Stack>
        <Card sx={{ padding: '15px' }} className="transtionCard">
          <CardContent>
            <form onSubmit={handleSubmit(onSearch)}>
              <Stack display="flex" flexDirection="row" className="temptranstaionStack">
                <Box padding={2} sx={{ flexBasis: '25%' }}>
                  <TextField
                    {...register('pbet_uid')}
                    fullWidth
                    placeholder="Bet Id"
                    value={BetId}
                    onChange={onchangeBetId}
                    label="Bet Id"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{config.placeBetShortName}</InputAdornment>,
                    }}
                  />
                  <Typography variant="body2" color="error">
                    {errors.pbet_uid?.message}
                  </Typography>
                </Box>
                <Box padding={2} sx={{ flexBasis: '25%' }} className="slectInput">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      name="type"
                      {...register('bet_place_type')}
                      // name="bet_place_type"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={type}
                      onChange={onchangeType}
                    >
                      {Type.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography variant="body2" color="error">
                    {errors.type?.message}
                  </Typography>
                </Box>

                <Box padding={2} sx={{ flexBasis: '25%' }} className="slectInput">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      name="status"
                      {...register('playerbet_status')}
                      id="playerbet_status"
                      value={status}
                      onChange={onchangeStatus}
                    >
                      <MenuItem key={''} value={' '}>
                        Select status
                      </MenuItem>
                      {Status.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box padding={2} sx={{ flexBasis: '25%' }} className="slectInput">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Search All User </InputLabel>
                    <Select
                      name="selectUser"
                      {...register('user_id')}
                      id="user_id"
                      value={selectUser}
                      onChange={onchangeUser}
                    >
                      <MenuItem key={''} value={' '}>
                        Select User
                      </MenuItem>
                      {users.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="self-end"
                className="temptranstaionStack alignitemNoraml"
              >
                <Box padding={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DesktopDateRangePicker']}>
                      <DemoItem component="DesktopDateRangePicker">
                        <DesktopDateRangePicker
                          localeText={{ start: 'Start Date', end: 'End Date' }}
                          className=""
                          size="small"
                          maxDate={minDate}
                          value={selectedDateRange}
                          onChange={handleDateChange}
                          calendars={1}
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>

                <Box display="flex" padding={2} sx={{ flexBasis: '25%' }}>
                  <FormControl>
                    <RadioGroup defaultValue={0} name="radio-buttons-group" sx={{ my: 1 }}>
                      <Stack display="flex" flexDirection="row">
                        <Box display="flex">
                          <Radio
                            {...register('isSettled')}
                            value={0}
                            onChange={() => setIsSettled(false)}
                            checked={!isSettled}
                          />
                          <Typography mt={1}>Created</Typography>
                        </Box>
                        <Box display="flex">
                          <Radio
                            {...register('isSettled')}
                            value={1}
                            onChange={() => setIsSettled(true)}
                            checked={isSettled}
                          />
                          <Typography mt={1}>Settled</Typography>
                        </Box>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box padding={2} sx={{ flexBasis: '25%' }}>
                  <Box>
                    <Typography id="range-slider" gutterBottom>
                      Price Range
                    </Typography>
                    <Slider
                      value={rangeValues}
                      onChange={handleRangeChange}
                      valueLabelDisplay="off"
                      min={10}
                      max={10000}
                      aria-labelledby="range-slider"
                    />
                    <Stack direction="row" justifyContent="space-between">
                      {' '}
                      <Typography fontWeight="bold">
                        {fNumber(rangeValues[0])} {showCurrency()}
                      </Typography>
                      <Typography>To</Typography>
                      <Typography fontWeight="bold">
                        {fNumber(rangeValues[1])} {showCurrency()}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
              <Box px={1}>
                <Stack display="flex" flexDirection="row" justifyContent="space-between" className="clearBtnStock">
                  <Box sx={{ margin: '5px' }}>
                    <FormControlLabel
                      label="PreMatch"
                      control={<Checkbox defaultChecked {...register('PreMatch')} />}
                    />
                    <FormControlLabel label="Live" control={<Checkbox {...register('Live')} />} />
                  </Box>
                  <Stack display="flex" flexDirection="row">
                    <Box margin={2} className="mrg0_padding_0">
                      <Button
                        onClick={clearFilter}
                        color="secondary"
                        variant="outlined"
                        size="large"
                        disabled={loading}
                        disableElevation
                      >
                        Clear
                      </Button>
                    </Box>
                    <Box margin={2} className="mrg0_padding_0">
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        size="large"
                        disabled={loading}
                        disableElevation
                      >
                        Search
                      </Button>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </form>
            <Box>
              <Stack direction="row" alignItems="left" sx={{}} className="flexWrap">
                <Typography variant="body1" className="statusLabel win" sx={{ mx: 3 }}>
                  Winning
                </Typography>

                <Typography variant="body1" className="statusLabel lost" sx={{ mx: 3 }}>
                  Lost
                </Typography>
                <Typography variant="body1" className="statusLabel running" sx={{ mx: 3 }}>
                  Not Settled
                </Typography>
                <Typography variant="body1" className="statusLabel reserve" sx={{ mx: 3 }}>
                  Reserve
                </Typography>
                <Typography variant="body1" className="statusLabel paid" sx={{ mx: 3 }}>
                  Paid
                </Typography>
                <Typography variant="body1" className="statusLabel cancelled" sx={{ mx: 3 }}>
                  Refund/Canceled
                </Typography>
                <Typography variant="body1" className="statusLabel half_win" sx={{ mx: 3 }}>
                  Half-Win
                </Typography>
                {/* <Typography variant="body1" className="statusLabel refused" sx={{ mx: 3 }}>
                  Refused By Oprator
                </Typography>
                <Typography variant="body1" className="statusLabel refusedUser" sx={{ mx: 3 }}>
                  Refused By User
                </Typography> */}
              </Stack>
            </Box>

            <Stack justifyContent="end" display="flex" flexDirection="row" margin={3} className="spaceBt">
              <Box marginRight={2}>
                <FormControl size="small" sx={{ minWidth: 180 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectVal}
                    defaultValue={1}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value={1}>Select All</MenuItem>
                    <MenuItem value={2}>Current Page</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                >
                  Export
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open1}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={() => handleFileTypeDownload(1)} disabled={disable} disableRipple>
                    <Icon icon="bxs:file-json" style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }} />
                    JSON
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => handleFileTypeDownload(2)} disabled={disable} disableRipple>
                    <Icon
                      icon="material-symbols:csv-outline"
                      style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }}
                    />
                    CSV
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />

                  <MenuItem onClick={() => handleFileTypeDownload(3)} disabled={disable} disableRipple>
                    <Icon
                      icon="file-icons:microsoft-excel"
                      style={{ color: '#323436db', fontSize: '20px', marginRight: '5px' }}
                    />
                    EXCEL
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Stack>
            <TableContainer
              // sx={{
              //   my: 2,
              //   '&::-webkit-scrollbar': {
              //     width: 20,
              //   },
              //   '&::-webkit-scrollbar-track': {
              //     backgroundColor: 'orange',
              //   },
              //   '&::-webkit-scrollbar-thumb': {
              //     backgroundColor: 'red',
              //     borderRadius: 2,
              //   },
              // }}
              component={Paper}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table" margin={1}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('pbet_uid')}>
                      Bet ID
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('created_at')} align="center">
                      Date
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('shop.name')} align="center">
                      Shop
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('user.username')} align="center">
                      User
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('bet_place_type')} align="center">
                      Type
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('stake_amount')} align="center">
                      Amount
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    {isSettled ? (
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('awarded_date')} align="center">
                        Payout Date
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                    ) : (
                      <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('win_amount')} align="center">
                        Possible Win
                        <IconButton aria-label="toggle password visibility">
                          {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('playerbet_status')} align="center">
                      Win
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('playerbet_status')} align="center">
                      Win-Lost-Void
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('playerbet_status')} align="center">
                      Status
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} align="center">
                      Details
                    </TableCell>
                  </TableRow>
                </TableHead>
                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {/* {betReportList.length > 0 ? ( */}
                {!isNotFound && !loading && (
                  <TableBody>
                    {betReportList.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{item.pbet_uid}</TableCell>
                        <TableCell align="center">{formatDateTimeUTC(item?.created_at, 'MM/DD/YYYY HH:mm')}</TableCell>
                        <TableCell align="center">
                          {item.shop === null ? 'Operator Generated' : item.shop.name}
                        </TableCell>
                        <TableCell align="center">
                          {item.user.username ? item.user.username : item.user.full_name}
                        </TableCell>
                        <TableCell align="center">{item.bet_place_type === 1 ? 'Single' : 'Combi'}</TableCell>
                        <TableCell align="center">
                          {formatNumber(item.stake_amount)} {showCurrency()}
                        </TableCell>
                        {isSettled ? (
                          <TableCell align="center">
                            {item?.awarded_date !== null
                              ? formatDateTimeUTC(item?.awarded_date, 'MM/DD/YYYY HH:mm')
                              : 'N/A'}
                          </TableCell>
                        ) : (
                          <TableCell align="center">
                            {formatNumber(item.win_amount)} {showCurrency()}
                          </TableCell>
                        )}
                        <TableCell align="center">
                          {item.playerbet_status === 2 ? formatNumber(item.win_amount) : 0.0} {showCurrency()}
                        </TableCell>
                        <TableCell align="center">{getWinLose(item.playerbet_status)}</TableCell>
                        <TableCell align="center">{getStatus(item.playerbet_status)}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => {
                              handleOpenDetails(item.pbet_uid);
                            }}
                          >
                            <Iconify icon={'mdi:eye'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
                {/* ) : ( */}
                {/* '' */}
                {/* )} */}
                {isNotFound && !loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={12} textTransform={'capitalize'}>
                      <Typography variant="h6"> data not found !</Typography>
                    </TableCell>
                  </TableRow>
                )}

                {!isNotFound && !loading && (
                  <TableFooter sx={{}}>
                    <TableRow>
                      <TableCell colSpan={5} align="left" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        Total Entries : {extra_data ? extra_data.current_page_items : 0}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        {betReportList.length > 0
                          ? formatNumber(
                              betReportList
                                .reduce(
                                  (accumulator, object) => parseFloat(accumulator) + parseFloat(object.stake_amount),
                                  0
                                )
                                .toFixed(2)
                            )
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        {betReportList.length > 0
                          ? formatNumber(
                              betReportList
                                .reduce(
                                  (accumulator, object) => parseFloat(accumulator) + parseFloat(object.win_amount),
                                  0
                                )
                                .toFixed(2)
                            )
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        {betReportList.length > 0
                          ? formatNumber(
                              betReportList
                                .reduce(
                                  (accumulator, object) =>
                                    parseFloat(accumulator) +
                                    (object.playerbet_status === 2 ? parseFloat(object.win_amount) : parseFloat(0.0)),
                                  0
                                )
                                .toFixed(2)
                            )
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell>
                      <TableCell
                        colSpan={3}
                        align="left"
                        sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
              {betReportList.length > 0 && !loading && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={extra_data ? extra_data.total_items : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default BettingReport;
