import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { useContext, useEffect, useState } from 'react';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
//
import PrivateRoute from './PrivateRoute';
import AgentOverview from './admin/Agent/AgentOverview';
import AgentBalanceManagement from './admin/Agent/BalanceManagement';
import CreditManagement from './admin/Agent/CreditManagement';
import FeedManagement from './admin/FeedManagement/FeedManagement';
import BalanceManagement from './admin/Operator/BalanceManagement';
import OperatorOverview from './admin/Operator/OperatorOverview';
import UserRTP from './admin/RiskManagement/UserRTP';
import AdminUserList from './admin/adminuser';
import AdminUserForm from './admin/adminuser/form';
import MenuPage from './admin/menu';
import MenuForm from './admin/menu/form';
import DepositAndWithdrawal from './admin/payment-gateway/DepositAndWithdrawal';
import PermissionPage from './admin/permission';
import RoleResponsibilityForm from './admin/permission/form';
import CashierReport from './admin/reports/CashierReport';
import LiveReports from './admin/reports/LiveReports';
import RecentWinningUsers from './admin/reports/RecentWinningUsers';
import ShopReport from './admin/reports/ShopReport';
import ShopStats from './admin/reports/ShopStats';
import SportsBookReports from './admin/reports/SportsBookReports';
import SupervisorReport from './admin/reports/SupervisorReport';
import UserReports from './admin/reports/UserReports';
import VirtualsReports from './admin/reports/VirtualsReports';
import RolePage from './admin/role';
import RoleForm from './admin/role/form';
import SettingsForm from './admin/setting';
import ShopPage from './admin/shop';
import ShopForm from './admin/shop/form';
import TopLeaguesPage from './admin/top_leagues';
import UserList from './admin/user';
import UserForm from './admin/user/form';
import UserTransactionIntegrity from './admin/RiskManagement/UserTransactionIntegrity';
import { UserContext } from './contexts/UserContext';
import SimpleLayout from './layouts/simple/SimpleLayout';
import AdminDashboardAppPage from './pages/AdminDashboardAppPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import { RouteMapping } from './utils/helper';
// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useContext(UserContext);
  const [customRoutes, setCustomRoutes] = useState([]);
  
  const generateCustomRoutes = () => {
    if (!user || !user.role_responsibility) return [];
    
    const user_routes = user.role_responsibility.length > 1 ? user.role_responsibility.filter(route => !route.slug.startsWith("admin")).sort((a, b) => a.id - b.id) : [];    
    const routes = RouteMapping(user_routes, user.type);    
    return routes;
  };

  useEffect(() => {
    if(user) setCustomRoutes(generateCustomRoutes());
  }, [user]);

  const adminRoutes = [
    { path: 'dashboard', element: <AdminDashboardAppPage /> },
    // Roles
    { path: 'role', element: <RolePage model={'role'} /> },
    { path: 'role/create', element: <RoleForm action={'Create'} /> },
    { path: 'role/edit/:id', element: <RoleForm action={'Edit'} /> },
    // Permissions
    { path: 'permission', element: <PermissionPage model={'permission'} /> },
    { path: 'permission/create', element: <RoleResponsibilityForm action={'Create'} /> },
    { path: 'permission/edit/:id', element: <RoleResponsibilityForm action={'Edit'} /> },
    // Admin Users
    { path: 'adminuser', element: <AdminUserList model={'adminuser'} /> },
    { path: 'adminuser/create', element: <AdminUserForm action={'Create'} /> },
    { path: 'adminuser/edit/:id', element: <AdminUserForm action={'Edit'} /> },
    // Users
    { path: 'user', element: <UserList model={'user'} /> },
    { path: 'user/create', element: <UserForm action={'Create'} /> },
    { path: 'user/edit/:id', element: <UserForm action={'Edit'} /> },
    // Shops
    { path: 'shop', element: <ShopPage model={'shop'} /> },
    { path: 'shop/create', element: <ShopForm action={'Create'} /> },
    { path: 'shop/edit/:id', element: <ShopForm action={'Edit'} /> },
    // Menus
    { path: 'menu', element: <MenuPage model={'menu'} /> },
    { path: 'menu/create', element: <MenuForm action={'Create'} /> },
    { path: 'menu/edit/:id', element: <MenuForm action={'Edit'} /> },
    // Top Leagues
    { path: 'top_leagues', element: <TopLeaguesPage model={'top_leagues'} /> },
    // Settings
    { path: 'setting', element: <SettingsForm /> },
    // Live Reports
    { path: 'live/shopwithlowbalance', element: <LiveReports /> },
    { path: 'live/shopstats', element: <ShopStats /> },
    { path: 'live/usersummary', element: <RecentWinningUsers /> },
    // Risk Management
    { path: 'riskmanagement/userrtp', element: <UserRTP /> },
    { path: 'riskmanagement/user/transaction/integrity', element: <UserTransactionIntegrity /> },
    // Payment gateway
    { path: 'paymentgateway/chapa', element: <DepositAndWithdrawal type={'chapa'} /> },
    // { path: 'paymentgateway/santimpay', element: <DepositAndWithdrawal type={'santim'}/> },
    // Feed Management
     { path: 'feedmanagement', element: <FeedManagement /> },
    // Reports
    { path: 'report/sportsbook/:tab', element: <SportsBookReports /> },
    { path: 'report/virtuals/:tab', element: <VirtualsReports /> },
    { path: 'report/shopreport', element: <ShopReport /> },
    { path: 'report/shopreport/:id', element: <ShopReport /> },
    { path: 'report/cashierreport', element: <CashierReport /> },
    { path: 'report/supervisorreport', element: <SupervisorReport /> },
    { path: 'report/userreport', element: <UserReports /> },
    { path: 'report/userreport/:id', element: <UserReports /> },
    // Operator
    { path: 'operator/overview', element: <OperatorOverview />},
    { path: 'operator/balancemanagement', element: <BalanceManagement />},
    // Agent
    { path: 'agent/overview', element: <AgentOverview />},
    { path: 'agent/balancemanagement', element: <AgentBalanceManagement />},
    { path: 'agent/creditmanagement', element: <CreditManagement />},
  ];

  const routes = useRoutes([
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/aviator/login',
      element: <LoginPage isAviator />,
    },
    {
      path: '/aviator-operator/login',
      element: <LoginPage isAviator />,
    },
    {
      path: '/admin',

      element: (
        <PrivateRoute type="admin">
          <DashboardLayout type={'admin'} />
        </PrivateRoute>
      ),
      children: [
        { element: <Navigate to="/admin/dashboard" />, index: true },
        ...adminRoutes,
      ],
    },
    {
      path: user?.type ? `/${user.type}` : `/`,
      element: user?.type ? <PrivateRoute type={user.type}><DashboardLayout type={user.type} /></PrivateRoute> : <SimpleLayout />,
      children: [
        { element: user?.type ? <Navigate to={`/${user.type}/dashboard`} /> : <Navigate to="/login" />, index: true },
        ...customRoutes,
        // { path: '404', element: <Page404 /> },
        { path: '*', element: <Page404 /> },
      ],
    },
    { path: '404', element: <Page404 /> },
  ]);

  return routes;
}
