import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { formatDate, formatNumber, getNestedValue, showCurrency, ToastError, ToastSuccess } from '../../utils/helper';
import { fNumber } from '../../utils/formatNumber';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { UserContext } from '../../contexts/UserContext';

const UserTransactionIntegrity = () => {
  const columns = [
    {
      id: 0,
      name: 'ID',
      align: 'center',
      columnName: 'user_id',
      isOrder: true,
    },
    {
      id: 1,
      name: 'Username',
      align: 'center',
      columnName: 'user.username',
      isOrder: false,
    },
    {
      id: 2,
      name: 'Bonus',
      align: 'center',
      columnName: 'bonus',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 3,
      name: 'Deposited',
      align: 'center',
      columnName: 'deposited_amount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 4,
      name: 'Withdrawal',
      align: 'center',
      columnName: 'withdrawal_amount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 5,
      name: 'Staked',
      align: 'center',
      columnName: 'stack_amount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 6,
      name: 'Won',
      align: 'center',
      columnName: 'win_amount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 7,
      name: 'Refunded/Cancelled',
      align: 'center',
      columnName: 'refund_amount',
      isOrder: false,
      isAmount: true,
    },
    {
      id: 8,
      name: 'Current Balance',
      align: 'center',
      columnName: 'current_balance',
      isOrder: true,
      isAmount: true,
    },
    {
      id: 9,
      name: 'Net Balance',
      align: 'center',
      columnName: 'net_balance',
      isOrder: true,
      isAmount: true,
    },
    {
      id: 10,
      name: 'Balance Variance',
      align: 'center',
      columnName: 'balance_variance',
      isOrder: true,
      isAmount: true,
    },
    {
      id: 11,
      name: 'Latest Calculated UT Timestamp',
      align: 'center',
      columnName: 'latest_calculated_ut_timestamp',
      isOrder: true,
      isDate: true,
    },
    {
      id: 12,
      name: 'Updated At',
      align: 'center',
      columnName: 'updated_at',
      isOrder: false,
      isDate: true,
    },
  ];

  const currentDate = new Date();
  const todayDate = dayjs(currentDate);
  const { user } = useContext(UserContext);
  const [dateDay, setdateDay] = useState('custom');
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [userTransactionIntegrityList, setUserTransactionIntegrityList] = useState([]);
  const [sortData, setSortData] = useState('ASC');
  const [sortBy, setSortBY] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, SetLoading] = useState(false);
  const [extra_data, setExtraData] = useState([]);
  const [filterName, setFilterName] = useState(null);
  // const [selectOperator, setSelectOperator] = useState(null);

  const { handleSubmit, register, setValue } = useForm();

  const fetchUserTransactionIntegrity = async ({
    sortBy = '',
    sortData = '',
    start_Date = '',
    end_Date = '',
    page = 1,
    rowsPerPage = 10,
    filterName = '',
  }) => {
    SetLoading(true);
    const start_date = new Date(start_Date === '' ? currentDate : start_Date);
    const endDate = new Date(end_Date === '' ? todayDate : end_Date);
    const utcStartDate = new Date(Date.UTC(start_date.getFullYear(), start_date.getMonth(), start_date.getDate()));
    const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

    try {
      const data = {
        user_token: user.token,
        data: {
          page: page + 1,
          size: rowsPerPage,
          search: filterName === null ? '' : filterName,
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData !== '' ? sortData : '',
          start_date: start_Date === '' ? '' : formatDate(utcStartDate, 'utcToClientStartDateTime'),
          end_date: end_Date === '' ? '' : formatDate(utcEndDate, 'utcToClientEndDateTime'),
        },
      };

      const response = await postData(`/admin/user/transactions/integrity/list`, data);
      setUserTransactionIntegrityList(response.data);
      setExtraData(response.extra_data);
      SetLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      SetLoading(false);
    }
  };

  const syncUserTransactionIntegrity = async () => {
    try {
      const data = {
        user_token: user.token,
        data: {},
      };

      const response = await fetchDataWithAuth(`/admin/user/transactions/integrity/sync`, data);
      ToastSuccess(response.message);
    } catch (error) {
      console.log('error :>> ', error);
      ToastError(error.response.data.message);
    }
  };


  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchUserTransactionIntegrity({
      sortBy,
      sortData: sortData === 'ASC' ? 'DESC' : 'ASC',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page,
      rowsPerPage,
      filterName,
    });
  };

  useEffect(() => {
    fetchUserTransactionIntegrity({
      sortBy,
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page,
      rowsPerPage,
      filterName,
    });
    setSelectedDateRange(['', '']);
  }, []);

  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const lastSevenDays = new Date(currentDate);
  lastSevenDays.setDate(currentDate.getDate() - 7);

  const lastMonth = new Date(currentDate);
  lastMonth.setMonth(currentDate.getMonth() - 1);

  const onchange = (event) => {
    setdateDay(event.target.value);
    switch (event.target.value) {
      case 'Today':
        return setSelectedDateRange([dayjs(currentDate), todayDate]);
      case 'Yesterday':
        return setSelectedDateRange([dayjs(yesterday), dayjs(yesterday)]);
      case 'Last 7 days':
        return setSelectedDateRange([dayjs(lastSevenDays), todayDate]);
      case 'Last 30 days':
        return setSelectedDateRange([dayjs(lastMonth), todayDate]);
      case 'custom':
        return setSelectedDateRange(['', '']);
      default:
        return setSelectedDateRange([todayDate, todayDate]);
    }
  };


  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] !== null ? newValue[0] : '', newValue[1] !== null ? newValue[1] : '']);
    if (newValue[0] && newValue[1]) {
      setSelectedDateRange(newValue);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchUserTransactionIntegrity({
      sortBy,
      sortData: sortBy ? sortData : '',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page: newPage,
      rowsPerPage,
      filterName,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    fetchUserTransactionIntegrity({
      sortBy,
      sortData: sortBy ? sortData : '',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page,
      rowsPerPage: event.target.value,
      filterName,
    });
  };

  const onSearch = () => {
    setPage(0);
    fetchUserTransactionIntegrity({
      sortBy,
      sortData: sortBy ? sortData : '',
      start_Date: selectedDateRange[0],
      end_Date: selectedDateRange[1],
      page: 0,
      rowsPerPage,
      filterName,
    });
  };

  const clearFilter = () => {
    setSelectedDateRange(['', '']);
    setSortBY('');
    setPage(0);
    setFilterName('');
    // setSelectOperator(null);
    setdateDay('custom');
    fetchUserTransactionIntegrity({ page: 0, rowsPerPage, filterName: null });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title> User Transaction Integrity | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="xl" className="cardDesign">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="uil:user-square" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              User Transaction Integrity
            </Typography>
          </Box>
        </Stack>

        <form onSubmit={handleSubmit(onSearch)}>
          <Card
            sx={{
              minWidth: 275,
              height: 'auto',
              marginTop: '45px',
            }}
          >
            <CardContent>
              <Stack display="flex" flexDirection="row" justifyContent="space-between" className="temptranstaionStack pd0_mrg0">
                <Box mt={3} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Dates</InputLabel>
                    <Select name="dates" id="dates" value={dateDay} onChange={onchange}>
                      <MenuItem value={'Today'}>Today</MenuItem>
                      <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                      <MenuItem value={'Last 7 days'}>Last 7 days</MenuItem>
                      <MenuItem value={'Last 30 days'}>Last 30 days</MenuItem>
                      <MenuItem value={'custom'}>Custom</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box padding={2} sx={{ flexBasis: '50%' }} className="slectInput width_100 pd0_mrg0">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DesktopDateRangePicker']}>
                      <DemoItem component="DesktopDateRangePicker">
                        <DesktopDateRangePicker
                          localeText={{ start: 'Start Date', end: 'End Date' }}
                          size="small"
                          maxDate={todayDate}
                          value={selectedDateRange}
                          onChange={handleDateChange}
                          disabled={dateDay !== 'custom'}
                          calendars={1}
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" className="slectInput width_100 pd0_mrg0">
                  <Button
                    onClick={clearFilter}
                    color="secondary"
                    variant="outlined"
                    size="large"
                    disableElevation
                    disabled={loading}
                    sx={{ marginRight: 2 }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="large"
                    disableElevation
                    disabled={loading}
                    sx={{ marginRight: 2 }}
                  >
                    Search
                  </Button>
                </Box>
              </Stack>
              <Stack display="flex" flexDirection="row" justifyContent="space-between" className="temptranstaionStack pd0_mrg0">
                <Box sx={{ flex: '1 1 auto' }} />
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" className="slectInput width_100 pd0_mrg0" style={{ marginTop: '10px', float: 'right' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Icon icon="uil:sync" />}
                    sx={{ marginRight: 2 }}
                    onClick={() => { syncUserTransactionIntegrity(); }}
                  >
                    Sync User Transaction Integrity
                  </Button>
                </Box>
              </Stack>

              <Box mt={3} sx={{ overflow: 'auto' }}>
                <TableContainer component={Paper} sx={{ height: 540, scrollbarWidth: 'thin' }}>
                  <Table sx={{ minWidth: '2000px' }} stickyHeader aria-label="simple table" margin={1}>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <>
                            {!column.isRowSkip && (
                              <TableCell
                                align={column.align}
                                sx={{ width: '250px', padding: '10px' }}
                                onClick={() => (column.isOrder ? handleSort(column.columnName) : '')}
                              >
                                {column.name}
                                {column.isOrder ? (
                                  <IconButton aria-label="toggle password visibility">
                                    {sortData === 'ASC' ? (
                                      <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                                    ) : (
                                      <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                                    )}
                                  </IconButton>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            )}
                          </>
                        ))}
                      </TableRow>
                    </TableHead>

                    {!loading ? (
                      <TableBody>
                        {userTransactionIntegrityList?.map((item) => (
                          <>
                            {' '}
                            <TableRow key={item._id} padding={0}>
                              {columns.map((column) => (
                                <>
                                  {!column.isRowSkip && (
                                    <TableCell align="center" sx={{ width: '250px' }}>
                                      {column.isDate
                                        ? formatDate(item?.[column.columnName], 'ticket datetime')
                                        : column.isCount
                                          ? fNumber(item?.[column.columnName])
                                          : column.isAmount
                                            ? `${fNumber(item?.[column.columnName])} ${showCurrency()}`
                                            : column.isPercentage
                                              ? `${formatNumber(item?.[column.columnName])} %`
                                              : getNestedValue(item, column.columnName) || '-'}
                                    </TableCell>
                                  )}
                                </>
                              ))}
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    ) : (
                      <TableCell align="center" colSpan={8}>
                        <CircularProgress />
                      </TableCell>
                    )}
                    {!loading && userTransactionIntegrityList.length <= 0 && (
                      <TableCell align="center" colSpan={8}>
                        <Typography variant="h6" textTransform={'capitalize'}>
                          {' '}
                          data not found !
                        </Typography>
                      </TableCell>
                    )}
                  </Table>
                  {!loading && userTransactionIntegrityList.length > 0 && (
                    <TableFooter sx={{}}>
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          align="left"
                          sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                        >
                          Total Entries : {extra_data ? extra_data.total_items : 0}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}

                  {/* </>
                  )} */}
                </TableContainer>
                {!loading && userTransactionIntegrityList.length > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={extra_data ? extra_data?.total_items : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </form>
      </Container>
    </>
  );
};

export default UserTransactionIntegrity;
