import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { fShortenNumber } from 'src/utils/formatNumber';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { ToastError, amountFormatter, formatDateTimeUTC, formatNumber, showCurrency } from '../../utils/helper';

const UserReports = () => {
  const [userReportList, setUserReportList] = useState([]);
  const [extra_data, setExtraData] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(['', '']);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectUser, setSelectUser] = useState(null);
  const [status, setStatus] = useState(3);
  const [userCount, setUserCount] = useState([]);
  const [sortData, setSortData] = useState('ASC');
  const [sortBy, setSortBY] = useState('');
  const [loading, SetLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([1, 2, 3, 4, 5, 6]);
  const { user } = useContext(UserContext);

  const params = useParams();

  const checkboxes = [
    { label: 'All', value: '' },
    { label: 'Deposit IN', value: 1 },
    { label: 'Withdrawal OUT', value: 2 },
    { label: 'Bet Placed IN', value: 3 },
    { label: 'Winnings OUT', value: 4 },
    { label: 'Refund', value: 5 },
    { label: 'Bonus IN', value: 6 },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const fetchUsersReport = async (selectedValues, sortBy, sortData, page, rowsPerPage) => {
    SetLoading(true);
    const startDate = new Date(selectedDateRange[0] || '');
    const endDate = new Date(selectedDateRange[1] || '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    try {
      const data = {
        user_token: user.token,
        data: {
          user_id: params.id !== undefined ? params.id : selectUser?.id,
          type: selectedValues,
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData !== '' ? sortData : '',
          status: status !== '' ? Number(status) : '',
          start_date: utcstartDate !== null ? utcstartDate : '',
          end_date: utcEndDate !== null ? utcEndDate : '',
        },
      };
      const response = await postData(`/admin/reports/user/transaction?page=${page + 1}&size=${rowsPerPage}`, data);
      SetLoading(false);
      setUserReportList(response.data);
      setExtraData(response.extra_data);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const fetchUsersCounts = async (selectedValues, sortBy, sortData, betFilter, clearData = 0) => {
    const startDate = new Date(selectedDateRange[0] || '');
    const endDate = new Date(selectedDateRange[1] || '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    SetLoading(true);
    try {
      const data = {
        user_token: user.token,
        data: {
          user_id: clearData !== 1 ? (selectUser?.id ? selectUser?.id : params.id) : '',
          type: selectedValues,
          order_key: sortBy !== '' ? sortBy : '',
          order_value: sortData !== '' ? sortData : '',
          status,
          start_date: clearData !== 1 ? (utcstartDate !== null ? utcstartDate : '') : '',
          end_date: clearData !== 1 ? (utcEndDate !== null ? utcEndDate : '') : '',
        },
      };
      const response = await postData(`/admin/reports/user/transaction/summary`, data);
      SetLoading(false);
      setUserCount(response.data[0]);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };
  const getUsers = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/admin/user/list`, data);
    const userData = response.data;
    setUsers(userData);
  };

  useEffect(() => {
    if (users && users.length > 0) {
      setSelectUser(users.find((user) => user.id === Number(params.id)));
    } else {
      getUsers();
    }
  }, [users]);
  useEffect(() => {
    if (!selectUser) onSearchCount(selectedValues, sortBy, '', selectUser?.id);
    if (!selectUser) fetchUsersReport(selectedValues, sortBy, '', page, rowsPerPage);
  }, []);

  const Status = [
    {
      id: 1,
      label: 'Open',
    },
    {
      id: 2,
      label: 'Pending',
    },
    {
      id: 3,
      label: 'Close',
    },
    {
      id: 4,
      label: 'Cancel',
    },
    {
      id: 5,
      label: 'Failed',
    },
  ];

  const onSearch = async (betFilter) => {
    setPage(0);
    setFilterData(betFilter);
    // if (
    //   selectUser?.id !== undefined ||
    //   status !== 3 ||
    //   betFilter.start_date !== undefined ||
    //   betFilter.end_date !== undefined ||
    //   selectedValues.length !== 6
    // ) {
    const startDate = new Date(selectedDateRange[0] || '');
    const endDate = new Date(selectedDateRange[1] || '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    setValue('type', selectedValues);
    if (selectedValues.includes(6)) {
      selectedValues.push(7); // to add deposit bonus
    }

    const data = {
      user_token: user.token,
      data: {
        user_id: selectUser ? selectUser?.id : params.id,
        type: selectedValues,
        order_key: sortBy !== '' ? sortBy : '',
        order_value: sortData !== '' ? sortData : '',
        status,
        start_date: utcstartDate !== null ? utcstartDate : '',
        end_date: utcEndDate !== null ? utcEndDate : '',
      },
    };
    toast.dismiss();
    try {
      SetLoading(true);
      fetchUsersCounts(selectedValues, sortBy, sortData, betFilter, '');
      const response = await postData(`/admin/reports/user/transaction?page=${1}&size=${rowsPerPage}`, data);
      SetLoading(false);
      setUserReportList(response.data);
      setFilter(data.data);
      setExtraData(response.extra_data);
    } catch (error) {
      ToastError(error.response.data.message);
      // }
    }
  };

  const onSearchCount = async (selectedValues, sortBy, sortData) => {
    const startDate = new Date(selectedDateRange[0] || '');
    const endDate = new Date(selectedDateRange[1] || '');
    const utcstartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    const data = {
      user_token: user.token,
      data: {
        user_id: selectUser ? selectUser?.id : params.id,
        type: selectedValues,
        order_key: sortBy !== '' ? sortBy : '',
        order_value: sortData !== '' ? sortData : '',
        status,
        start_date: utcstartDate !== null ? utcstartDate : '',
        end_date: utcEndDate !== null ? utcEndDate : '',
      },
    };
    toast.dismiss();
    try {
      const response = await postData(`/admin/reports/user/transaction/summary`, data);
      setUserCount(response.data[0]);
    } catch (error) {
      ToastError(error.response.data.message);
    }
  };

  const handleDateChange = (newValue) => {
    setSelectedDateRange([newValue[0] === null ? '' : newValue[0] || '', newValue[1] === null ? '' : newValue[0] || '']);
    if (newValue[1] && newValue[0]) {
      const start_date = new Date(newValue[0]);
      // start_date.setHours(12, 0, 0);

      const endDate = new Date(newValue[1]);
      const hours = endDate.getHours();
      const minutes = endDate.getMinutes();
      const seconds = endDate.getSeconds();
      if (hours === 0 || minutes === 0 || seconds === 0) {
        endDate.setHours(23, 59, 59);
      }
      setSelectedDateRange([dayjs(start_date), dayjs(endDate)]);
      const utcstartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
      const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
      setValue('start_date', utcstartDate.toISOString());
      setValue('end_date', utcEndDate.toISOString());
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchUsersReport(selectedValues, sortBy, sortData, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    fetchUsersReport(selectedValues, sortBy, sortData, 0, event.target.value);
  };

  const getType = (type) => {
    switch (type) {
      case 1:
        return 'Deposit IN';
      case 2:
        return 'Withdrawal OUT';
      case 3:
        return 'Bet Placed IN';
      case 4:
        return 'Winnings OUT';
      case 5:
        return 'Refund';
      case 6:
        return 'Sign Up Bonus IN';
      case 7:
        return 'Deposit Bonus IN';
      default:
        return '-';
    }
  };
  const getPaymentType = (type) => {
    switch (type) {
      case '1':
        return 'Chapa';
      case '2':
        return 'Santim';
      case '3':
        return 'MTN';
      case '4':
        return 'Orange Money';
      default:
        return 'N/A';
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return 'Open';
      case 2:
        return 'Pending';
      case 3:
        return 'Closed';
      case 4:
        return 'Cancel';
      case 5:
        return 'Failed';
      default:
        return 'Open';
    }
  };

  //   const handleOpenDetails = async (pbet_uid) => {
  //     const data = {
  //       user_token: user.token,
  //       data: {
  //         pbet_uid: pbet_uid.replace(`${config.placeBetShortName}`, ''),
  //       },
  //     };
  //     toast.dismiss();
  //     try {
  //       const response = await postData(`/cashier/get/bet/details`, data);
  //       const betData = response;
  //       setOpen(true);
  //       setBetDetail(betData.data[0]);
  //       setBetBarcode(betData.data[0].pbet_url);
  //     } catch (error) {
  //       console.log('error :>> ', error);
  //       ToastError(error.response.data.message);
  //     }
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //     setBetDetail(null);
  //     setBetBarcode(null);
  //   };
  // export start
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const saveBlob = (blob, filename) => {
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = filename;
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //   };

  //   const handleFileTypeDownload = async (filetype) => {
  //     const data = {
  //       user_token: user.token,
  //       data: {
  //         page: selectVal === 2 ? page + 1 : '',
  //         row_per_page: selectVal === 2 ? rowsPerPage : '',
  //         file_type: filetype,
  //         order_key: sortBy !== '' ? sortBy : '',
  //         order_value: sortData,
  //         pbet_uid: BetId,
  //         playerbet_status: filterData.playerbet_status,
  //         user_id: filterData.user_id,
  //         start_date: filterData.start_date,
  //         end_date: filterData.end_date,

  //         headers: ['Bet ID', 'Date', 'Shop', 'User', 'Type', 'Amount', 'Possible Win', 'Win', 'Win-Lost-Void', 'Status'],
  //       },
  //     };
  //     try {
  //       const BettingReport = await postData(`/cashier/bet/report/export`, data);
  //       let mimeType;
  //       let fileExtension;

  //       if (filetype === 2) {
  //         mimeType = 'text/csv';
  //         fileExtension = '.csv';
  //       } else if (filetype === 3) {
  //         mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  //         fileExtension = '.xlsx';
  //       } else if (filetype === 1) {
  //         mimeType = 'application/json';
  //         fileExtension = '.json';
  //       } else {
  //         // Handle unsupported file types

  //         return;
  //       }

  //       const blob = await fetch(`${REACT_APP_API_URL}${BettingReport.data[0].file_path}`).then((response) =>
  //         response.blob()
  //       );

  //       const filename = `BettingReport${fileExtension}`;
  //       saveBlob(blob, filename);
  //     } catch (error) {
  //       console.log('error :>> ', error);
  //     }
  //     setAnchorEl(null);
  //   };

  // export end

  const handleSort = (sortBy) => {
    setSortBY(sortBy);
    setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    fetchUsersReport(selectedValues, sortBy, sortData === 'ASC' ? 'DESC' : 'ASC', page, rowsPerPage);
  };

  const clearFilter = async () => {
    setSelectedDateRange(['', '']);
    reset({
      type: '',
    });
    setValue(('type', ''));
    setValue(('user_id', ''));
    setFilter([]);
    setFilterData([])
    setSelectUser(null);
    setStatus(3);
    setPage(0);
    setSelectedValues([1, 2, 3, 4, 5, 6]);

    // if (
    //   selectUser !== null ||
    //   selectUser !== undefined ||
    //   selectUser !== ''||
    //   status !== 3 ||
    //   selectedDateRange[0] !== '' ||
    //   selectedDateRange[1] !== '' ||
    //   selectedValues.length !== 6
    // ) {

    const data = {
      user_token: user.token,
      data: {
        user_id: '',
        type: '',
        order_key: '',
        order_value: '',
        status: 3,
        start_date: '',
        end_date: '',
      },
    };
    SetLoading(true);

    const counts = await postData(`/admin/reports/user/transaction/summary`, data);
    const response = await postData(`/admin/reports/user/transaction?page=${1}&size=${rowsPerPage}`, data);
    SetLoading(false);
    setUserReportList(response.data);
    setUserCount(counts.data[0]);
    // setFilter(filter);
    setExtraData(response.extra_data);
    // }
  };
  const onchangeUser = (event, newValue) => {
    setValue('user_id', newValue?.id);
    setSelectUser(newValue);
  };
  const onchangeStatus = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (selectedValues.includes('')) {
      setSelectedValues([1, 2, 3, 4]);
    }
  }, [selectedValues]);

  const handleCheckboxChange = (value) => {
    if (value === '') {
      if (selectedValues.length === 6) {
        setSelectedValues([]);
      } else {
        setSelectedValues([1, 2, 3, 4, 5, 6]);
      }
    } else {
      const valueIndex = selectedValues.indexOf(value);
      if (valueIndex !== -1) {
        setSelectedValues(selectedValues.filter((v) => v !== value));
      } else {
        setSelectedValues([...selectedValues, value]);
      }
    }
  };

  const isCheckboxChecked = (value) => {
    if (selectedValues.length === 6) {
      return selectedValues;
    }
    if (value === '') {
      return selectedValues.length === 6;
    }
    return selectedValues.includes(value);
  };

  const isNotFound = !userReportList.length;

  const min_date = new Date();
  const minDate = dayjs(min_date);

  return (
    <>
      <Helmet>
        <title> User Report | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="xl" className="dashboardPage cardDesign">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Box display="flex">
            <Icon
              icon="icon-park-twotone:permissions"
              style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }}
            />
            <Typography variant="h4" gutterBottom>
              Users Report
            </Typography>
          </Box>
        </Stack>

        <Grid
          container
          className="boxGrid nomarginLR"
          spacing={3}
          justifyContent="stretch"
          sx={{
            '& .MuiGrid-root': {
              margin: 1,
              border: '3px solid ',
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#fff',
            },
            // '& .MuiTypography-root': { color: '#061B64' },
          }}
        >
          <Grid item xs={12} sm={6} md={2} className="blue_900">
            <Card>
              <CardContent>
                <Stack color="#061B64">
                  <Typography sx={{ fontSize: '15px' }}>Total Bet Amounts</Typography>
                  <Typography variant="h5">
                    {userCount.total_bet_amount !== undefined
                      ? amountFormatter(userCount.total_bet_amount).slice(0, -3)
                      : '00.00'}
                    <Typography variant="caption"> {showCurrency()}</Typography>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={2} className="coffy_300">
            <Card>
              <CardContent>
                <Stack color="#7F5408">
                  <Typography sx={{ fontSize: '15px' }}>Total Win Amounts</Typography>
                  <Typography variant="h5">
                    {userCount.total_win_amount !== undefined
                      ? amountFormatter(userCount.total_win_amount).slice(0, -3)
                      : '00.00'}{' '}
                    <Typography variant="caption"> {showCurrency()}</Typography>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={2} className="purple_500">
            <Card>
              <CardContent>
                <Stack color="#b56b40">
                  <Typography sx={{ fontSize: '15px' }}>Total Deposits</Typography>
                  <Typography variant="h5">
                    {userCount.total_deposits !== undefined
                      ? amountFormatter(userCount.total_deposits).slice(0, -3)
                      : '00.00'}
                    <Typography variant="caption"> {showCurrency()}</Typography>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={2} className="purple_800">
            <Card>
              <CardContent>
                <Stack color="#2F0192">
                  <Typography sx={{ fontSize: '15px' }}>Total withdrawals</Typography>
                  <Typography variant="h5">
                    {userCount.total_withdrawals !== undefined
                      ? amountFormatter(userCount.total_withdrawals).slice(0, -3)
                      : '00.00'}
                    <Typography variant="caption"> {showCurrency()}</Typography>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={2} className="green_100">
            <Card>
              <CardContent>
                <Stack color="#dd9646">
                  <Typography sx={{ fontSize: '15px' }}>
                    {selectUser?.id !== undefined ? 'Current Balance  ' : 'Total Users Balance'}
                  </Typography>
                  <Typography variant="h5">
                    {userCount.total_balance !== undefined
                      ? amountFormatter(userCount.total_balance).slice(0, -3)
                      : '00.00'}
                    <Typography variant="caption"> {showCurrency()}</Typography>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className="green_500">
            <Card>
              <CardContent>
                <Stack color="#45925b">
                  <Typography sx={{ fontSize: '15px' }}>Total Bonus</Typography>
                  <Typography variant="h5">
                    {userCount.total_bonus !== undefined
                      ? amountFormatter(userCount.total_bonus).slice(0, -3)
                      : '00.00'}
                    <Typography variant="caption"> {showCurrency()}</Typography>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* </Stack> */}
        <Card className="transtionCard">
          <CardContent>
            <form onSubmit={handleSubmit(onSearch)}>
              <Stack display="flex" flexDirection="row" className="temptranstaionStack pd0_mrg0">
                <Box padding={2} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    <InputLabel id="demo-simple-select-label"> Select Status</InputLabel>
                    <Select
                      name="status"
                      {...register('status')}
                      id="status"
                      value={status || 3}
                      onChange={onchangeStatus}
                    >
                      {/* <MenuItem key={''} value={' '}>
                        Select status
                      </MenuItem> */}
                      {Status.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box padding={2} sx={{ flexBasis: '25%' }} className="slectInput width_100 pd0_mrg0">
                  <FormControl sx={{ minWidth: 150 }} fullWidth>
                    {/* {users && users.length > 0 && ( */}
                    <Autocomplete
                      fullWidth
                      name="id"
                      disablePortal
                      id="id"
                      {...register('user_id')}
                      options={users}
                      autoHighlight
                      value={selectUser}
                      onChange={onchangeUser}
                      getOptionLabel={(option) => option.username}
                      renderInput={(params) => (
                        <TextField {...params} value={selectUser} selectUser label=" Select Users" />
                      )}
                    />
                  </FormControl>
                </Box>

                <Box sx={{ flexBasis: '50%', mt: '10px' }} className="slectsInput width_100">
                  {/* <Typography variant="body1">Date Range</Typography> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DesktopDateRangePicker']}>
                      <DemoItem component="DesktopDateRangePicker">
                        <DesktopDateRangePicker
                          localeText={{ start: 'Start UTC Date', end: 'End UTC Date' }}
                          size="small"
                          maxDate={minDate}
                          value={selectedDateRange}
                          onChange={handleDateChange}
                          calendars={1}
                          format="DD-MM-YYYY HH:mm"
                          slotProps={{
                            textField: {
                              error: false,
                            },
                          }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Stack>
              <Stack display="flex" flexDirection="row" alignItems="self-end" className="stakeColumn">
                <Box sx={{ flexBasis: '100%' }} padding={2}>
                  {checkboxes.map((checkbox) => (
                    <FormControlLabel
                      key={checkbox.value}
                      label={checkbox.label}
                      control={
                        <Checkbox
                          checked={isCheckboxChecked(checkbox.value)}
                          onChange={() => handleCheckboxChange(checkbox.value)}
                        />
                      }
                    />
                  ))}
                </Box>
                <Box px={1} className="width_100">
                  <Stack display="flex" flexDirection="row" justifyContent="space-between">
                    <Stack display="flex" flexDirection="row">
                      <Box margin={2} className="pd0_mrg0Right">
                        <Button
                          onClick={clearFilter}
                          color="secondary"
                          variant="outlined"
                          size="large"
                          disableElevation
                          disabled={loading}
                        >
                          Clear
                        </Button>
                      </Box>
                      <Box margin={2} className="pd0_mrg0Right">
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          size="large"
                          disabled={loading}
                          disableElevation
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </form>

            {/* <Stack justifyContent="end" display="flex" flexDirection="row" margin={3}>
              <Box marginRight={2}>
                <FormControl size="small" sx={{ minWidth: 180 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectVal}
                    defaultValue={1}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value={1}>Select All</MenuItem>
                    <MenuItem value={2}>Current Page</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                >
                  Export
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open1}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={() => handleFileTypeDownload(1)} disableRipple>
                    <Icon icon="bxs:file-json" style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }} />
                    JSON
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem onClick={() => handleFileTypeDownload(2)} disableRipple>
                    <Icon
                      icon="material-symbols:csv-outline"
                      style={{ color: '#323436db', fontSize: '23px', marginRight: '5px' }}
                    />
                    CSV
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />

                  <MenuItem onClick={() => handleFileTypeDownload(3)} disableRipple>
                    <Icon
                      icon="file-icons:microsoft-excel"
                      style={{ color: '#323436db', fontSize: '20px', marginRight: '5px' }}
                    />
                    EXCEL
                  </MenuItem>
                </StyledMenu>
              </Box>
            </Stack> */}
            <TableContainer
              sx={{ maxHeight: 540 }}
              // sx={{
              //   my: 2,
              //   '&::-webkit-scrollbar': {
              //     width: 20,
              //   },
              //   '&::-webkit-scrollbar-track': {
              //     backgroundColor: 'orange',
              //   },
              //   '&::-webkit-scrollbar-thumb': {
              //     backgroundColor: 'red',
              //     borderRadius: 2,
              //   },
              // }}
              component={Paper}
            >
              <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table" margin={1}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('txn_id')}>
                      Transaction ID
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>

                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('user.username')} align="center">
                      Username
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('user.phone')} align="center">
                      Phone No
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('pbet_uid')} align="center">
                      Bet ID
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('type')} align="center">
                      Type
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('payment_type')} align="center">
                      Payment Type
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>

                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('debit')} align="center">
                      Debit
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('credit')} align="center">
                      Credit
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('created_at')} align="center">
                      Date
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('status')} align="center">
                      Status
                      <IconButton aria-label="toggle password visibility">
                        {sortData === 'ASC' ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* {userReportList.length > 0 ? ( */}
                {!loading ? (
                  <TableBody>
                    {userReportList.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{item?.txn_id}</TableCell>

                        <TableCell align="center">
                          {item.user?.username ? item.user?.username : 'Offline User'}
                        </TableCell>
                        <TableCell align="center">
                          {item.user !== null
                            ? `+ ${item.user?.country_code}
                          ${item.user?.phone}`
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="center">{item.pbet_uid !== null ? item.pbet_uid : '-'}</TableCell>
                        <TableCell align="center">{getType(item.type)}</TableCell>
                        <TableCell align="center">{getPaymentType(item.payment_type)} </TableCell>
                        <TableCell align="center">
                          {item.debit !== null ? `${formatNumber(item.debit)} ${showCurrency()}` : '-'}{' '}
                        </TableCell>
                        <TableCell align="center">
                          {item.credit !== null ? `${formatNumber(item.credit)} ${showCurrency()}` : '-'}{' '}
                        </TableCell>
                        <TableCell align="center">{formatDateTimeUTC(item?.created_at, 'MM/DD/YYYY HH:mm')}</TableCell>
                        <TableCell align="center">{getStatus(item?.status)}</TableCell>
                        {/* <TableCell align="center">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => {
                              handleOpenDetails(item.pbet_uid);
                            }}
                          >
                            <Iconify icon={'mdi:eye'} />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {userReportList.length <= 0 && !loading ? (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <Typography variant="h6" textTransform={'capitalize'}>
                        {' '}
                        data not found !
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}

                {!isNotFound && !loading && (
                  <TableFooter sx={{}}>
                    <TableRow>
                      <TableCell colSpan={6} align="left" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        Total Entries : {extra_data ? extra_data.current_page_items : 0}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        {userReportList.length > 0
                          ? formatNumber(
                            userReportList
                              .reduce(
                                (accumulator, object) =>
                                  parseFloat(accumulator) + (object.debit !== null ? parseFloat(object.debit) : 0),
                                0
                              )
                              .toFixed(2)
                          )
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                        {userReportList.length > 0
                          ? formatNumber(
                            userReportList
                              .reduce(
                                (accumulator, object) =>
                                  parseFloat(accumulator) + (object.credit !== null ? parseFloat(object.credit) : 0),
                                0
                              )
                              .toFixed(2)
                          )
                          : 0.0}{' '}
                        {showCurrency()}
                      </TableCell>
                      <TableCell
                        colSpan={3}
                        align="left"
                        sx={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
            {userReportList.length > 0 && !loading && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={extra_data ? extra_data.total_items : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default UserReports;
