const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  apiUrl: process.env.REACT_APP_API_URL,
  apiAdminUrl: process.env.REACT_APP_ADMIN_URL,
  apiAviatorUrl: process.env.REACT_APP_AVIATOR_API_URL,
  secretKey: process.env.REACT_APP_SECRET_KEY,
  currency: 'NGN',
  Domain: 'Bet Monkey',
  placeBetShortName: process.env.REACT_APP_PLACE_BET_SHORT_NAME,
  allowedPlaceBetLength: process.env.REACT_APP_ALLOWED_PLACE_BET_LENGTH,
  allowedTempBetLength: process.env.REACT_APP_ALLOWED_TEMP_BET_LENGTH,
};

export default config;
