import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Box, Card, CardContent, CircularProgress, Container, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { UserContext } from '../../contexts/UserContext';
import { fetchDataWithAuth, postData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { generateHash, getTimeStamp, ToastError, ToastSuccess } from '../../utils/helper';

const schema = yup.object().shape({
  vat: yup.number().required('VAT is required'),
  incomeTaxThreshold: yup
    .number()
    .min(0, 'Income Tax Threshold must be greater than or equal to 0')
    .required('Income Tax Threshold is required'),
  incomeTax: yup.number().required('Income Tax is required'),
  minimumBetAmount: yup
    .number()
    .min(0, 'Minimum Bet Amount must be greater than or equal to 0')
    .required('Minimum Bet Amount is required'),
  maximumBetAmount: yup
    .number()
    .min(0, 'Maximum Bet Amount must be greater than or equal to 0')
    .required('Maximum Bet Amount is required'),
  maximumWinAmount: yup
    .number()
    .min(0, 'Maximum Win Amount must be greater than or equal to 0')
    .required('Maximum Win Amount is required'),
  dailyMaxBetAmount: yup
    .number()
    .min(0, 'Daily Maximum Bet Amount must be greater than or equal to 0')
    .required('Daily Maximum Bet Amount is required'),
  weeklyMaxBetAmount: yup
    .number()
    .min(0, 'Weekly Maximum Bet Amount must be greater than or equal to 0')
    .required('Weekly Maximum Bet Amount is required'),
  monthlyMaxBetAmount: yup
    .number()
    .min(0, 'Monthly Maximum Bet Amount must be greater than or equal to 0')
    .required('Monthly Maximum Bet Amount is required'),
  signupbonus: yup
    .number()
    .min(0, 'Sign up Bonus must be greater than or equal to 0')
    .required('Sign up Bonus is required'),
  withdrawlimitmultiply: yup
    .number()
    .min(0, 'Withdrawal Bonus Multiplier must be greater than or equal to 0')
    .required('Withdrawal Bonus Multiplier is required'),
  dailywithdrawlimit: yup
    .number()
    .min(0, 'Daily Withdrawal Limit must be greater than or equal to 0')
    .required('Daily Withdrawal Limit is required'),
  netPlaceBetUsagePercentageForWithdrawal: yup
    .number()
    .min(0, 'Withdrawal Criteria (%) must be greater than or equal to 0')
    .required('Withdrawal Criteria (%) is required'),
  // depositbonusthreshold: yup
  // .number()
  // .min(0, 'Deposit Bonus Threshold must be greater than or equal to 0')
  // .required('Deposit Bonus Threshold is required'),
  // depositbonus: yup
  // .number()
  // .min(0, 'Deposit Bonus must be greater than or equal to 0')
  // .required('Deposit Bonus is required'),
});

const SettingsForm = () => {
  const Redirect = useNavigate();
  const [loading, setLoading] = useState(false)

  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [disable, setDisable] = useState(false);
  const getSettings = async () => {
    setLoading(true)
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/admin/system/setting/list`, data);
    const settingsData = response.data;

    setValue('vat', settingsData[0].value);
    setValue('incomeTaxThreshold', settingsData[1].value);
    setValue('incomeTax', settingsData[2].value);
    setValue('minimumBetAmount', settingsData[3].value);
    setValue('maximumBetAmount', settingsData[4].value);
    setValue('maximumWinAmount', settingsData[5].value);
    setValue('dailyMaxBetAmount', settingsData[6].value);
    setValue('weeklyMaxBetAmount', settingsData[7].value);
    setValue('monthlyMaxBetAmount', settingsData[8].value);
    setValue('signupbonus', settingsData[11].value);
    setValue('withdrawlimitmultiply', Number(settingsData[12].value));
    setValue('dailywithdrawlimit', Number(settingsData[13].value));
    setValue('netPlaceBetUsagePercentageForWithdrawal', settingsData[16].value);
    // setValue('depositbonusthreshold', Number(settingsData[14].value));
    // setValue('depositbonus', Number(settingsData[15].value));
    setLoading(false)
  };

  useEffect(() => {
    getSettings();
  }, []);

  const onSubmit = async (settingsData) => {
    // Perform form submission logic here
    setLoading(true)
    setDisable(true)
    toast.dismiss();
    let data = null;
    let RoleData = null;
    try {
      data = {
        user_token: user.token,
        data: {
          settings: [
            {
              id: 1,
              value: settingsData.vat,
            },
            {
              id: 2,
              value: settingsData.incomeTaxThreshold,
            },
            {
              id: 3,
              value: settingsData.incomeTax,
            },
            {
              id: 4,
              value: settingsData.minimumBetAmount,
            },
            {
              id: 5,
              value: settingsData.maximumBetAmount,
            },
            {
              id: 6,
              value: settingsData.maximumWinAmount,
            },
            {
              id: 7,
              value: settingsData.dailyMaxBetAmount,
            },
            {
              id: 8,
              value: settingsData.weeklyMaxBetAmount,
            },
            {
              id: 9,
              value: settingsData.monthlyMaxBetAmount,
            },
            {
              id: 13,
              value: settingsData.signupbonus,
            },
            {
              id: 14,
              value: settingsData.withdrawlimitmultiply,
            },
            {
              id: 15,
              value: settingsData.dailywithdrawlimit,
            },
            {
              id: 16,
              value: settingsData.depositbonusthreshold,
            },
            {
              id: 17,
              value: settingsData.depositbonus,
            },
            {
              id: 18,
              value: settingsData.netPlaceBetUsagePercentageForWithdrawal,
            },
          ],
          date_time: getTimeStamp(),
        },
      };
      const hashSignature = generateHash(data.data);
      data.data.hash = hashSignature;
      RoleData = await postData('/admin/system/setting/update', data);
      ToastSuccess(RoleData.message);
      setTimeout(() => {
        toast.dismiss();
        getSettings();
      }, 800);
      setDisable(false)
    } catch (error) {
      console.log('error2 :>> ', error);
      ToastError(error.response.data.message);
      setDisable(false)
    }
    setLoading(false)
  };

  const handleChange = (event) => {
    console.log('handleChange', event.target.checked);
  };
  return (
    <>
      <Helmet>
        <title> Configuration | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>

      <Container maxWidth="md">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="uil:setting" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Configuration
            </Typography>
          </Box>
        </Stack>
        <Card>
          <CardContent className="pd0_mrg0">
            {loading ?
              <Stack display="flex" flexDirection="row" justifyContent="center">
                <CircularProgress size={35} />
              </Stack> : <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="column">
                  <Stack direction="row" className="stakeColumn">
                    <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                      <TextField
                        label="VAT (%)"
                        {...register('vat', {
                          setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                        })}
                        type="number"
                        placeholder="VAT (%)"
                        error={!!errors.vat}
                        helperText={errors.vat?.message}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          classes: { root: 'input-label' },
                        }}
                        InputProps={{ classes: { root: 'input-field' } }}
                      />
                    </Box>

                    <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                      <TextField
                        label="Income Tax Threshold"
                        placeholder="Income Tax Threshold"
                        {...register('incomeTaxThreshold', {
                          setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                        })}
                        type="number"
                        error={!!errors.incomeTaxThreshold}
                        helperText={errors.incomeTaxThreshold?.message}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          classes: { root: 'input-label' },
                        }}
                        InputProps={{ classes: { root: 'input-field' } }}
                      />
                    </Box>
                  </Stack>

                  <Stack direction="row" className="stakeColumn">
                    <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                      <TextField
                        label="Income Tax (%)"
                        placeholder="Income Tax (%)"
                        {...register('incomeTax', {
                          setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                        })}
                        type="number"
                        error={!!errors.incomeTax}
                        helperText={errors.incomeTax?.message}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          classes: { root: 'input-label' },
                        }}
                        InputProps={{ classes: { root: 'input-field' } }}
                      />
                    </Box>

                    <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                      <TextField
                        label="Minimum Bet Amount"
                        placeholder="Minimum Bet Amount"
                        {...register('minimumBetAmount', {
                          setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                        })}
                        type="number"
                        error={!!errors.minimumBetAmount}
                        helperText={errors.minimumBetAmount?.message}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          classes: { root: 'input-label' },
                        }}
                        InputProps={{ classes: { root: 'input-field' } }}
                      />
                    </Box>
                  </Stack>

                  <Stack direction="row" className="stakeColumn">
                    <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                      <TextField
                        label="Maximum Bet Amount"
                        placeholder="Maximum Bet Amount"
                        {...register('maximumBetAmount', {
                          setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                        })}
                        type="number"
                        error={!!errors.maximumBetAmount}
                        helperText={errors.maximumBetAmount?.message}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          classes: { root: 'input-label' },
                        }}
                        InputProps={{ classes: { root: 'input-field' } }}
                      />
                    </Box>

                    <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                      <TextField
                        label="Maximum Win Amount"
                        placeholder="Maximum Win Amount"
                        {...register('maximumWinAmount', {
                          setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                        })}
                        type="number"
                        error={!!errors.maximumWinAmount}
                        helperText={errors.maximumWinAmount?.message}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          classes: { root: 'input-label' },
                        }}
                        InputProps={{ classes: { root: 'input-field' } }}
                      />
                    </Box>
                  </Stack>
                </Stack>

                <Stack direction="row" className="stakeColumn">
                  <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                    <TextField
                      label="Daily Maximum Bet Amount"
                      placeholder="Daily Maximum Bet Amount"
                      {...register('dailyMaxBetAmount', {
                        setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                      })}
                      type="number"
                      error={!!errors.dailyMaxBetAmount}
                      helperText={errors.dailyMaxBetAmount?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                  </Box>

                  <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                    <TextField
                      label="Sign Up Bonus"
                      placeholder="Sign Up Bonus"
                      {...register('signupbonus', {
                        setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                      })}
                      type="number"
                      error={!!errors.signupbonus}
                      helperText={errors.signupbonus?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                  </Box>
                </Stack>

                <Stack direction="row" className="stakeColumn">
                  <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                    <TextField
                      label="Withdraw Limit Multiply"
                      placeholder="Withdraw Limit Multiply"
                      {...register('withdrawlimitmultiply', {
                        setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                      })}
                      type="number"
                      error={!!errors.withdrawlimitmultiply}
                      helperText={errors.withdrawlimitmultiply?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                  </Box>

                  <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                    <TextField
                      label="Daily Withdraw Limit"
                      placeholder="Daily Withdraw Limit"
                      {...register('dailywithdrawlimit', {
                        setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                      })}
                      type="number"
                      error={!!errors.dailywithdrawlimit}
                      helperText={errors.dailywithdrawlimit?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                  </Box>
                </Stack>

                <Stack direction="row" className="stakeColumn">
                  <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                    <TextField
                      label="Withdrawal Criteria (%)"
                      placeholder="Total net place bet / Total deposit * 100"
                      {...register('netPlaceBetUsagePercentageForWithdrawal', {
                        setValueAs: (value) => (value === "" ? undefined : parseFloat(value)),
                      })}
                      type="number"
                      error={!!errors.netPlaceBetUsagePercentageForWithdrawal}
                      helperText={errors.netPlaceBetUsagePercentageForWithdrawal?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                        classes: { root: 'input-label' },
                      }}
                      InputProps={{ classes: { root: 'input-field' } }}
                    />
                  </Box>
                </Stack>

                {/* <Stack direction="row" className="stakeColumn">
                <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                  <TextField
                    label="Deposit Bonus Threshold"
                    {...register('depositbonusthreshold')}
                    error={!!errors.depositbonusthreshold}
                    helperText={errors.depositbonusthreshold?.message}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                  />
                </Box>

                <Box display="flex" padding={2} sx={{ flexBasis: '50%' }} className="width_100">
                  <TextField
                    label="Deposit Bonus (%)"
                    {...register('depositbonus')}
                    error={!!errors.depositbonus}
                    helperText={errors.depositbonus?.message}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: 'input-label' },
                    }}
                    InputProps={{ classes: { root: 'input-field' } }}
                  />
                </Box>
              </Stack> */}
                <Box padding={2}>
                  <Button type="submit" variant="contained" color="primary" size="large" disabled={disable}>
                    Update Settings
                  </Button>
                </Box>
              </form>}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default SettingsForm;
