import { Icon } from '@iconify/react';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { aviatorPostData } from '../../utils/api';
import { MY_GLOBAL_CONST } from '../../utils/GlobalConstant';
import { formatDate, formatNumber, getNestedValue, showCurrency } from '../../utils/helper';
import { fNumber } from '../../utils/formatNumber';
import Label from '../../components/label';
import config from '../../config';
import Iconify from '../../components/iconify';

const OperatorRTP = () => {
  const [loading, setLoading] = useState(false);

  const [list, setList] = useState([]);
  const [extraData, setExtraData] = useState(null);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [search, setSearch] = useState('');

  const currentDate = new Date();
  const todayDate = dayjs(currentDate);
  const [dateDay, setDateDay] = useState('Custom');
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);

  const [sortData, setSortData] = useState('');
  const [sortColumn, setSortColumn] = useState('');

  const [isDownloadFile, setIsDownloadFile] = useState(false);

  const columns = useMemo(
    () => [
      { name: 'Name', columnName: 'name', align: 'center', isOrder: false },
      { name: 'Username', columnName: 'username', align: 'center', isOrder: false },
      { name: 'Email', columnName: 'email', align: 'center', isOrder: false },
      { name: 'Player', columnName: 'totalPlayerCount', align: 'center', isOrder: false, isCount: true },
      { name: 'Bet', columnName: 'totalBetCount', align: 'center', isOrder: false, isCount: true },
      { name: 'Bet Amount', columnName: 'totalBetAmount', align: 'center', isOrder: false, isAmount: true },
      { name: 'Win Bet', columnName: 'totalWinBetCount', align: 'center', isOrder: false, isCount: true },
      { name: 'Win Bet Amount', columnName: 'totalWinBetAmount', align: 'center', isOrder: false, isAmount: true },
      { name: 'Lost Bet', columnName: 'totalLostBetCount', align: 'center', isOrder: false, isCount: true },
      { name: 'Lost Bet Amount', columnName: 'totalLostBetAmount', align: 'center', isOrder: false, isAmount: true },
      { name: 'Cancel Bet', columnName: 'totalCancelBetCount', align: 'center', isOrder: false, isCount: true },
      {
        name: 'Cancel Bet Amount',
        columnName: 'totalCancelBetAmount',
        align: 'center',
        isOrder: false,
        isAmount: true,
      },
      { name: 'Net Bet Amount', columnName: 'totalNetBetAmount', align: 'center', isOrder: false, isAmount: true },
      { name: 'Profit', columnName: 'totalProfit', align: 'center', isOrder: false, isAmount: true },
      { name: 'RTP %', columnName: 'totalRTP', align: 'center', isOrder: false, isPercentage: true },
      { name: 'GGR %', columnName: 'ggrPercentage', align: 'center', isOrder: false, isPercentage: true },
      { name: 'Total GGR', columnName: 'totalGGR', align: 'center', isOrder: false, isAmount: true },
      { name: 'Profit After GGR', columnName: 'totalProfitAfterGGR', align: 'center', isOrder: false, isAmount: true },
    ],
    []
  );

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const clearFilter = () => {
    setSearch('');
    setDateDay('Custom');
    setSelectedDateRange([null, null]);
    setIsDownloadFile(false);
  };

  const handleFilterByName = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };

  const saveBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const onSearch = async () => {
    setLoading(true);
    try {
      const body = {
        data: {
          search,
          startDate: selectedDateRange[0],
          endDate: selectedDateRange[1],
        },
      };

      if (isDownloadFile) {
        body.data.isDownloadFile = true;
      } else {
        body.data.page = page;
        body.data.limit = rowsPerPage;
      }

      const response = await aviatorPostData('/admin/report/admin-user/rtp', body);

      if (isDownloadFile) {
        const REACT_APP_AVIATOR_API_URL = config.apiAviatorUrl.slice(0, -6);
        const blob = await fetch(`${REACT_APP_AVIATOR_API_URL}${response.filePath}`).then((response) =>
          response.blob()
        );
        const date = currentDate.toISOString().split('T')[0];
        const time = currentDate.toTimeString().split(' ')[0];
        const filename = `Operator_RTP - ${date} at ${time}.xlsx`;
        saveBlob(blob, filename);
        setIsDownloadFile(false);
      } else {
        setList(response.data.list);
        setExtraData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const downloadExcel = () => {
    setIsDownloadFile(true);
  };

  useEffect(() => {
    onSearch();
  }, [page, rowsPerPage, isDownloadFile]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortData(sortData === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortColumn(column);
      setSortData('ASC');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDateChange = (newValue) => {    
    // const [startDate, endDate] = newValue;
    // const start = startDate || null;
    // const end = endDate || null;
    // setSelectedDateRange([start, end]);

    if (newValue[0] && newValue[1]) {
      const start_date = new Date(newValue[0]);
      // start_date.setHours(12, 0, 0);
      const endDate = new Date(newValue[1]);
      // endDate.setHours(23, 59, 59);
      const hours = endDate.getHours() || 23;
      const minutes = endDate.getMinutes() || 59;
      const seconds = endDate.getSeconds() || 59;
      endDate.setHours(hours, minutes, seconds);
      setSelectedDateRange([dayjs(start_date), dayjs(endDate)]);
    }
  };

  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const lastSevenDays = new Date(currentDate);
  lastSevenDays.setDate(currentDate.getDate() - 7);

  const lastMonth = new Date(currentDate);
  lastMonth.setMonth(currentDate.getMonth() - 1);

  // const onchange = (e) => {
  //   setDateDay(e.target.value);
  //   switch (e.target.value) {
  //     case 'Today':
  //       return setSelectedDateRange([dayjs(currentDate), todayDate]);
  //     case 'Yesterday':
  //       return setSelectedDateRange([dayjs(yesterday), dayjs(yesterday)]);
  //     case 'Last 7 days':
  //       return setSelectedDateRange([dayjs(lastSevenDays), todayDate]);
  //     case 'Last 30 days':
  //       return setSelectedDateRange([dayjs(lastMonth), todayDate]);
  //     case 'Custom':
  //       return setSelectedDateRange(['', '']);
  //     default:
  //       return setSelectedDateRange([todayDate, todayDate]);
  //   }
  // };

  const onchange = (e) => {
    setDateDay(e.target.value);
  
    const calculateDateRange = (daysAgoStart, daysAgoEnd = 0) => {
      const startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - daysAgoStart);
      startDate.setHours(0, 0, 0);
  
      const endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() - daysAgoEnd);
      endDate.setHours(daysAgoEnd === 0 ? currentDate.getHours() : 23, 59, 59);
  
      return [dayjs(startDate), dayjs(endDate)];
    };
  
    switch (e.target.value) {
      case 'Today':
        setSelectedDateRange(calculateDateRange(0));
        break;
  
      case 'Yesterday':
        setSelectedDateRange(calculateDateRange(1, 1));
        break;
  
      case 'Last 7 days':
        setSelectedDateRange(calculateDateRange(7));
        break;
  
      case 'Last 30 days':
        setSelectedDateRange(calculateDateRange(30));
        break;
  
      case 'Custom':
        setSelectedDateRange(['', '']);
        break;
  
      default:
        setSelectedDateRange(calculateDateRange(0));
        break;
    }
  };
  

  return (
    <Container maxWidth="xl" className="cardDesign">
      <Helmet>
        <title>Operator RTP | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Box display="flex">
          <Icon icon="tabler:report" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
          <Typography variant="h4" gutterBottom>
            Operator RTP
          </Typography>
        </Box>
      </Stack>

      <form onSubmit={handleSubmit(onSearch)}>
        <Card
          sx={{
            minWidth: 275,
            height: 'auto',
            marginTop: '45px',
          }}
        >
          <CardContent>
            <Stack display="flex" flexDirection="row" className="temptranstaionStack pd0_mrg0">
              {/* Date Filter */}
              <Box mt={3} sx={{ flexBasis: '20%' }} className="slectInput width_100 pd0_mrg0">
                <FormControl sx={{ minWidth: 150 }} fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Dates</InputLabel>
                  <Select name="dates" id="dates" value={dateDay} onChange={onchange}>
                    <MenuItem value={'Today'}>Today</MenuItem>
                    <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                    <MenuItem value={'Last 7 days'}>Last 7 days</MenuItem>
                    <MenuItem value={'Last 30 days'}>Last 30 days</MenuItem>
                    <MenuItem value={'Custom'}>Custom</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box padding={2} sx={{ flexBasis: '40%' }} className="slectInput width_100 pd0_mrg0">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DesktopDateRangePicker']}>
                    <DemoItem component="DesktopDateRangePicker">
                      <DesktopDateRangePicker
                        localeText={{ start: 'Start Date', end: 'End Date' }}
                        size="small"
                        maxDate={todayDate}
                        value={selectedDateRange}
                        onChange={handleDateChange}
                        disabled={dateDay !== 'Custom'}
                        calendars={1}
                        format="DD-MM-YYYY HH:mm"
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </Box>

              {/* Search */}
              <Box padding={3} sx={{ flexBasis: '19%' }} className="slectInput width_100 pd0_mrg0">
                <TextField value={search} type="text" onChange={handleFilterByName} placeholder="Search..." />
              </Box>

              {/* Buttons */}
              <Box margin={3} className="slectInput width_100 pd0_mrg0">
                <Button
                  onClick={clearFilter}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  disableElevation
                  disabled={loading}
                >
                  Clear
                </Button>
              </Box>
              <Box margin={3} className="slectInput width_100 pd0_mrg0">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  size="large"
                  disableElevation
                  disabled={loading}
                >
                  Search
                </Button>
              </Box>
            </Stack>

            {/* Export Buttons */}
            <Stack
              display="flex"
              flexDirection="row"
              sx={{ justifyContent: 'flex-end' }}
              className="temptranstaionStack pd0_mrg0"
            >
              <Box
                display="flex"
                margin={2}
                sx={{ flexBasis: '20%', justifyContent: 'flex-end' }}
                className="slectInput width_100 pd0_mrg0"
              >
                <Button
                  onClick={downloadExcel}
                  color="secondary"
                  variant="contained"
                  size="large"
                  disabled={loading}
                  disableElevation
                >
                <Iconify icon={'eva:download-outline'} />
                  {/* Download */}
                </Button>
              </Box>
            </Stack>

            {/* Table */}
            <Box mt={3} sx={{ overflow: 'auto' }}>
              <TableContainer component={Paper} sx={{ height: 540, scrollbarWidth: 'thin' }}>
                <Table sx={{ minWidth: '2200px' }} stickyHeader aria-label="simple table" margin={1}>
                  {/* Table Header */}
                  <TableHead>
                    <TableRow>
                      {columns.map(
                        (column, index) =>
                          !column.isRowSkip && (
                            <TableCell
                              key={index}
                              align={column.align}
                              sx={{ width: '250px', padding: '10px' }}
                              onClick={() => (column.isOrder ? handleSort(column.columnName) : '')}
                            >
                              {column.name}
                              {column.isOrder ? (
                                <IconButton aria-label="toggle password visibility">
                                  {sortData === 'ASC' ? (
                                    <ArrowUpwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                                  ) : (
                                    <ArrowDownwardOutlinedIcon sx={{ fontSize: '16px !important' }} />
                                  )}
                                </IconButton>
                              ) : (
                                ''
                              )}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  </TableHead>

                  {/* Table Body */}
                  <TableBody>
                    {list.map((row) => (
                      <TableRow key={row._id} padding={0}>
                        {columns.map(
                          (column) =>
                            !column.isRowSkip &&
                            !column.isEmpty && (
                              <TableCell align={column.align} sx={{ width: '250px' }}>
                                {column.isStatus ? (
                                  <Label
                                    color={
                                      column.stateName.find((s) => s.label === getNestedValue(row, column.columnName))
                                        ?.color
                                    }
                                  >
                                    {getNestedValue(row, column.columnName)}
                                  </Label>
                                ) : column.isDate ? (
                                  formatDate(row?.[column.columnName], 'ticket datetime')
                                ) : column.isCount ? (
                                  fNumber(row[column.columnName])
                                ) : column.isAmount ? (
                                  `${fNumber(row[column.columnName])} ${showCurrency()}`
                                ) : column.isPercentage ? (
                                  `${formatNumber(row[column.columnName])} %`
                                ) : (
                                  getNestedValue(row, column.columnName) || '-'
                                )}
                              </TableCell>
                            )
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {!loading && list.length <= 0 && (
                  <TableCell align="center" colSpan={8}>
                    <Typography variant="h6" textTransform={'capitalize'}>
                      {' '}
                      data not found !
                    </Typography>
                  </TableCell>
                )}
              </TableContainer>
              {!loading && list.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={extraData ? extraData.pagination.totalCounts : 0}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </form>
    </Container>
  );
};

export default OperatorRTP;
