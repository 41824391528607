import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  CardContent,
  Container,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import Card from '@mui/material/Card';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// import Table from 'src/theme/overrides/Table';
import { Helmet } from 'react-helmet-async';
import * as yup from 'yup';
import Iconify from '../components/iconify/Iconify';
import { UserContext } from '../contexts/UserContext';
import { MY_GLOBAL_CONST } from '../utils/GlobalConstant';
import { fetchDataWithAuth, postData } from '../utils/api';
import { ToastError, ToastSuccess, formatDate, formatNumber, generateHash, getTimeStamp, roundto2, showCurrency } from '../utils/helper';
import BetSlip from './BetSlip';
import PrintBetSlip from './PrintBetSlip';
import config from '../config';

const validationSchema = yup.object().shape({
  // pbet_uid: yup.lazy((value) => {
  //   if (value) {
  //     return yup
  //       .string()
  //       .required('This field is required')
  //       .matches(/^[^\s].*/, 'starting spaced is not allowed')
  //       .matches(/^[0-9]+$/, 'Only numbers are allowed')
  //       .length(10, 'Must be exactly 10 digits');
  //   }
  //   return yup.string();
  // }),
});
const BetTicketCode = () => {
  const [setUsers] = useState([]);
  const { user, updateBalance, fetchBalance, systemSetting } = useContext(UserContext);
  const vatPercentage = Number(systemSetting['VAT (%)']);
  const incomeTaxThreshold = Number(systemSetting['Income Tax Threshold']);
  const incomeTaxPercentage = Number(systemSetting['Income Tax (%)']);
  const minimumBetAmount = Number(systemSetting['Minimum Bet Amount']);
  const maximumBetAmount = Number(systemSetting['Maximum Bet Amount']);
  const maximumWinAmount = Number(systemSetting['Maximum Win Amount']);
  const [contentToShow, setContentToShow] = useState('BetSection');
  const [betTicketData, setBetTicketData] = useState('');
  const [fixtures, setFixtures] = useState('');
  const [BetDetail, setBetDetail] = useState(null);
  const [BetBarcode, setBetBarcode] = useState(null);
  const [betAmount, setBetAmount] = useState(minimumBetAmount);
  const [player, setPlayer] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [disable, setDisable] = useState(false);
  const [placeBetDisable, setPlaceBetDisable] = useState(false);
  const {
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const params = useParams();

  useEffect(() => {
    Rebet();
    getUsers();
  }, [setUsers]);

  const getUsers = async () => {
    const data = {
      user_token: user.token,
      data: {},
    };
    const response = await fetchDataWithAuth(`/${user.type}/user/list`, data);
    const userData = response.data;
    setUsers(userData);
  };
  // const handlePlayerChange = async (event, newValue) => {
  //   if(newValue != null) {
  //     const data = {
  //       user_token: user.token,
  //       data: {
  //         user_id: newValue.id
  //       },
  //     };
  //     const response = await postData(`/${user.type}/user/balance`, data);
  //     newValue.balance = response.data[0].balance;
  //   }
  //   setPlayer(newValue);
  // };

  const handleAmount = (amt) => {
    setBetAmount(amt);
  };

  const handleIncrement = () => {
    setBetAmount(betAmount + 1);
  };

  const handleDecrement = () => {
    if (betAmount > 10) {
      setBetAmount(betAmount - 1);
    }
  };
  const handleSetContentToShow = (value) => {
    setBetTicketData(null);
    setBetDetail(null);
    setBetAmount(minimumBetAmount);
    setPlayer('');
    setSearchValue('');
    setContentToShow(value);
  };
  const getSelectedOdd = (fixture) => {
    const oddData = Object.prototype.hasOwnProperty.call(fixture, 'Market')
      ? fixture.Market.Odds[0]
      : fixture.Markets[0].Odds[0];
    if (oddData.name === '1') {
      return (
        <>
          <Typography variant="body2">
            {Object.prototype.hasOwnProperty.call(fixture, 'Market')
              ? 'Winner'
              : fixture.Markets[0].name === '1X2'
              ? '1X2 (Match Results)'
              : fixture.Markets[0].name}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {fixture.Participants_home.name}
          </Typography>
          <Typography variant="body2">{oddData.price.toFixed(2)}</Typography>
        </>
      );
    }
    if (oddData.name === 'X') {
      return (
        <>
          <Typography variant="body2">
            {Object.prototype.hasOwnProperty.call(fixture, 'Market')
              ? 'Winner'
              : fixture.Markets[0].name === '1X2'
              ? '1X2 (Match Results)'
              : fixture.Markets[0].name}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            Draw
          </Typography>
          <Typography variant="body2">{oddData.price.toFixed(2)}</Typography>
        </>
      );
    }
    if (oddData.name === '2') {
      return (
        <>
          <Typography variant="body2">
            {Object.prototype.hasOwnProperty.call(fixture, 'Market')
              ? 'Winner'
              : fixture.Markets[0].name === '1X2'
              ? '1X2 (Match Results)'
              : fixture.Markets[0].name}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {fixture.Participants_away.name}
          </Typography>
          <Typography variant="body2">{oddData.price.toFixed(2)}</Typography>
        </>
      );
    }
    if (oddData.name === '1X') {
      return (
        <>
          <Typography variant="body2">
            {Object.prototype.hasOwnProperty.call(fixture, 'Market')
              ? 'Winner'
              : fixture.Markets[0].name === '1X2'
              ? '1X2 (Match Results)'
              : fixture.Markets[0].name}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {fixture.Participants_home.name} or Draw
          </Typography>
          <Typography variant="body2">{oddData.price.toFixed(2)}</Typography>
        </>
      );
    }
    if (oddData.name === 'X2') {
      return (
        <>
          <Typography variant="body2">
            {Object.prototype.hasOwnProperty.call(fixture, 'Market')
              ? 'Winner'
              : fixture.Markets[0].name === '1X2'
              ? '1X2 (Match Results)'
              : fixture.Markets[0].name}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            Draw or {fixture.Participants_away.name}
          </Typography>
          <Typography variant="body2">{oddData.price.toFixed(2)}</Typography>
        </>
      );
    }
    if (oddData.name === '12') {
      return (
        <>
          <Typography variant="body2">
            {Object.prototype.hasOwnProperty.call(fixture, 'Market')
              ? 'Winner'
              : fixture.Markets[0].name === '1X2'
              ? '1X2 (Match Results)'
              : fixture.Markets[0].name}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            No Draw
          </Typography>
          <Typography variant="body2">{oddData.price.toFixed(2)}</Typography>
        </>
      );
    }
    return (
      <>
        <Typography variant="body2">
          {Object.prototype.hasOwnProperty.call(fixture, 'Market')
            ? 'Winner'
            : fixture.Markets[0].name === '1X2'
            ? '1X2 (Match Results)'
            : fixture.Markets[0].name}
        </Typography>
        <Typography variant="body2" fontWeight="bold">
          {oddData.name} {oddData.line !== '' ? oddData.line : ''}
        </Typography>
        <Typography variant="body2">{oddData.price.toFixed(2)}</Typography>
      </>
    );
  };

  const getSelectedOddData = (fixture) => {
    const oddData = Object.prototype.hasOwnProperty.call(fixture, 'Market')
      ? fixture.Market.Odds[0]
      : fixture.Markets[0].Odds[0];
    return roundto2(oddData.price);
  };

  let totalOdds = betTicketData
    ? fixtures?.reduce((accumulator, object) => accumulator * getSelectedOddData(object), 1)
    : 0;
  totalOdds = roundto2(totalOdds);  
  // const vatAmount = (vatPercentage / 100) * betAmount;
  // const actualBetAmount = betAmount - vatAmount;
  const taxDivisor = 1 + vatPercentage / 100;
  let actualBetAmount = betAmount / taxDivisor;
  actualBetAmount = roundto2(actualBetAmount);
  let vatAmount = betAmount - (actualBetAmount);
  vatAmount = roundto2(vatAmount);
  let winngAmount = totalOdds * actualBetAmount;
  winngAmount = roundto2(winngAmount);
  let taxAmount = winngAmount > incomeTaxThreshold ? (incomeTaxPercentage / 100) * winngAmount : 0;
  taxAmount = roundto2(taxAmount);
  winngAmount = taxAmount > 0 ? winngAmount - taxAmount : winngAmount;
  const actualwining = winngAmount + taxAmount;

  const onSubmit = async () => {
    setPlaceBetDisable(true)
    toast.dismiss();

    if (parseFloat(betAmount) < parseFloat(minimumBetAmount)) {
      ToastError(`Stake amount should be minimum ${minimumBetAmount}`);
      setPlaceBetDisable(false)
      return false;
    }

    if (parseFloat(betAmount) > parseFloat(winngAmount)) {
      ToastError(`Win must be bigger than stake ${betAmount} ${showCurrency()}`);
      setPlaceBetDisable(false)
      return false;
    }

    if (betAmount > maximumBetAmount) {
      ToastError(`stake ${betAmount} ${showCurrency()} should be less than ${maximumBetAmount} ${showCurrency()}`);
      setPlaceBetDisable(false)
      return false;
    }

    if (winngAmount > maximumWinAmount) {
      ToastError(`possible winning ${winngAmount} ${showCurrency()} should be less than ${maximumWinAmount} ${showCurrency()}`);
      setPlaceBetDisable(false)
      return false;
    }

    try {
      const data = {
        user_token: user.token,
        data: {
          user_id: player ? player.id : '',
          fixture_id: betTicketData.Fixtures.map((item) => item.id),
          stake_amount: betAmount,
          total_odds: totalOdds,
          vat_amount: vatAmount,
          total_amount: actualBetAmount,
          win_amount: winngAmount,
          tax_amount: taxAmount,
          date_time: getTimeStamp(),
          odd_id: fixtures?.map((item) => ({
            fixture_id: item.id,
            odd_id: item.Markets[0].Odds[0].id,
            odd_price: roundto2(item.Markets[0].Odds[0].price),
          })),
        },
      };
      const hashSignature = generateHash(data.data);
      data.data.hash = hashSignature;
      const response = await postData(`/${user.type}/user/place/bet`, data);
      const betData = response;
      // console.log('response place bet', response);
      ToastSuccess(betData?.message);
      setBetDetail(betData.data[0].bet);
      updateBalance(betData.data[0].balance);
      setBetBarcode(betData.data[0].pbet_url);
      setContentToShow('BetSlip');
      setPlaceBetDisable(false)
    } catch (error) {
      console.log('betplaced  error :>> ', error);
      ToastError(error?.response?.data?.message);
      setPlaceBetDisable(false)
    }
    fetchBalance();
    return 0;
  };
  const handleDeleteBet = (fixtureId) => {
    const updatedItems = fixtures?.filter((item) => item.id !== fixtureId);
    setFixtures(updatedItems);
  };

  const Rebet = async () => {
    if (params.pbet_uid) {
      setSearchValue(params.pbet_uid);
      const data = {
        user_token: user.token,
        data: {
          pbet_uid: params.pbet_uid,
        },
      };
      toast.dismiss();
      try {
        const response = await postData(`/${user.type}/get/temp/bet/details`, data);
        const tempBetData = response.data[0];
        setBetTicketData(tempBetData);
        setFixtures(tempBetData.Fixtures);
        const stake_amount = Number(tempBetData.stake_amount);
        setBetAmount(stake_amount);
      } catch (error) {
        ToastError(error?.response?.data?.message);
      }
    }
  };

  const handleBetSearch = async (values) => {
    setDisable(true)
    const data = {
      user_token: user.token,
      data: {
        pbet_uid: searchValue,
      },
    };
    toast.dismiss();
    try {
      const response = await postData(`/${user.type}/get/temp/bet/details`, data);
      const tempBetData = response.data[0];
      setBetTicketData(tempBetData);
      setFixtures(tempBetData.Fixtures);
      const stake_amount = Number(tempBetData.stake_amount);
      setBetAmount(stake_amount);
      setDisable(false)
    } catch (error) {
      ToastError(error?.response?.data?.message);
      setDisable(false)
    }
  };
  const onHandleChange = async (event) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, '');
    setSearchValue(sanitizedValue);
  };

  return (
    <>
      <Helmet>
        <title> Bet Ticket Code | {`${MY_GLOBAL_CONST.main_title}`} Admin </title>
      </Helmet>
      <Container maxWidth="md" className="betTktCodePage">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box display="flex">
            <Icon icon="ic:outline-code" style={{ fontSize: '23px', marginRight: '3px', marginTop: '6px' }} />
            <Typography variant="h4" gutterBottom>
              Bet Ticket Code
            </Typography>
          </Box>
        </Stack>

        <Stack>
          {contentToShow === 'BetSection' ? (
            <Card sx={{}}>
              <CardContent>
                <Box>
                  <Stack
                    component={'form'}
                    onSubmit={handleSubmit(handleBetSearch)}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className="tempStack"
                  >
                    <TextField
                      variant="outlined"
                      label="Enter Temp number"
                      disableUnderline
                      fullWidth
                      {...register('pbet_uid')}
                      value={searchValue}
                      onChange={onHandleChange}
                      sx={{ width: '85%', marginRight: '10px' }}
                      id="search-text"
                      type="text"
                      placeholder="Enter Temp number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">TEMP</InputAdornment>,
                      }}
                    />

                    <Button type="submit" variant="contained" size="large" disabled={searchValue.length < config.allowedTempBetLength || disable}>
                      Search
                    </Button>
                  </Stack>

                  {betTicketData && fixtures.length > 0 ? (
                    <>
                      <Stack flexDirection="row" sx={{ mb: 1, mt: 2 }}>
                        <Box width="100%">
                          {/* {user.type === 'operator' || user.shop[0].operator_id != null ? 
                            <Autocomplete
                              fullWidth
                              id="player_box"
                              options={users}
                              autoHighlight
                              onChange={handlePlayerChange}
                              getOptionLabel={(option) => option.username}
                              renderInput={(params) => <TextField {...params} label="Player" />}
                            /> 
                            : <></>
                          } */}
                        </Box>
                      </Stack>
                      {player ? (
                        <Box sx={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography fontWeight="bold" variant="body1" sx={{ width: '20%' }}>
                              Player ID <Typography variant="body1">{player.id}</Typography> 
                            </Typography>
                            <Typography fontWeight="bold" variant="body1" sx={{ width: '20%' }}>
                              Username <Typography variant="body1">{player.username}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" variant="body1" sx={{ width: '20%' }}>
                              Balance <Typography variant="body1">{player.balance} <b>{showCurrency()}</b></Typography>
                            </Typography>
                          </Stack>
                        </Box>
                      ) : (
                        ''
                      )}
                      {fixtures?.map((item) => (
                        <>
                          <Box
                            sx={{
                              // backgroundColor: '#f5f5f5',
                              // padding: '10px',
                              borderRadius: '5px',
                              borderBottom: '1px solid #d7d7d7',
                              pt: 1,
                              pb: 1,
                            }}
                          >
                            <Stack direction="row" alignItems="left" justifyContent="space-between">
                              <Typography
                                variant="body2"
                                // pl="16px"
                                pt="8px"
                                sx={{ textTransform: 'uppercase', fontWeight: '600', fontSize: '14px' }}
                              >
                                {item.sport_name} - {item.location_name} - {item.league_name}
                              </Typography>
                              <Iconify
                                onClick={() => handleDeleteBet(item.id)}
                                icon="iconamoon:trash-duotone"
                                color="black"
                                sx={{ cursor: 'pointer', fontSize: '50px' }}
                              />
                            </Stack>
                            <Stack my={1} direction="row" alignItems="center" justifyContent="space-between">
                              <Typography variant="body2" fontWeight="bold">
                                {item.id}
                              </Typography>
                              <Typography variant="body2">
                                {item.Participants_home.name} - {item.Participants_away.name}
                              </Typography>
                              {getSelectedOdd(item)}
                            </Stack>
                            <Box display="flex">
                              <Typography fontSize="13px">Start at </Typography>
                              <Typography fontSize="13px" ml="10px" fontWeight="500">
                                {formatDate(item.start_date, 'ticketpreview')}
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      ))}
                      <Stack direction="row" alignItems="center" justifyContent="space-between" my={2}>
                        <Typography variant="body1" fontWeight="bold" mt={2}>
                          BET
                        </Typography>

                        <Stack className="inputBxHolder" direction="row">
                          <div className="betSuggetionDv">
                            <ul
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                marginTop: '11px',
                              }}
                            >
                              <li style={{ listStyle: 'none' }}>
                                <Button
                                  sx={{ backgroundColor: 'grey.900', '&:hover': { backgroundColor: 'grey.800' } }}
                                  variant="contained"
                                  disableElevation
                                  onClick={() => {
                                    handleAmount(20);
                                  }}
                                >
                                  20
                                </Button>
                              </li>
                              <li style={{ listStyle: 'none' }}>
                                <Button
                                  sx={{ backgroundColor: 'grey.900', '&:hover': { backgroundColor: 'grey.800' } }}
                                  variant="contained"
                                  disableElevation
                                  onClick={() => {
                                    handleAmount(50);
                                  }}
                                >
                                  50
                                </Button>
                              </li>

                              <li style={{ listStyle: 'none' }}>
                                <Button
                                  sx={{ backgroundColor: 'grey.900', '&:hover': { backgroundColor: 'grey.800' } }}
                                  variant="contained"
                                  disableElevation
                                  onClick={() => {
                                    handleAmount(100);
                                  }}
                                >
                                  100
                                </Button>
                              </li>
                              <li style={{ listStyle: 'none' }}>
                                <Button
                                  sx={{ backgroundColor: 'grey.900', '&:hover': { backgroundColor: 'grey.800' } }}
                                  variant="contained"
                                  disableElevation
                                  onClick={() => {
                                    handleAmount(150);
                                  }}
                                >
                                  150
                                </Button>
                              </li>
                            </ul>
                          </div>
                          <Stack className="inputBxInc" direction="row">
                            <Typography
                              onClick={handleDecrement}
                              sx={{
                                backgroundColor: 'grey.900',
                                color: 'white',
                                minWidth: '35px',

                                textAlign: 'center',
                                cursor: 'pointer',
                                borderRadius: '5px',
                                fontWeight: '700',
                                fontSize: '18px',
                                padding: '5px 0',
                              }}
                            >
                              -
                            </Typography>
                            <input
                              type="number"
                              style={{
                                backgroundColor: 'rgb(243 243 243)',
                                minWidth: '30px',
                                border: 0,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                margin: '0 5px',
                                borderRadius: '5px',
                                fontSize: '16px',
                              }}
                              onChange={(e) => {
                                handleAmount(Number(e.target.value));
                              }}
                              value={betAmount}
                              onInput={(e) => {
                                  e.target.value = e.target.value.replace(/[^0-9]*/g, '');
                              }}
                            />
                            <Typography
                              sx={{
                                backgroundColor: 'grey.900',
                                color: 'white',
                                minWidth: '35px',

                                textAlign: 'center',
                                cursor: 'pointer',
                                borderRadius: '5px',
                                fontWeight: '700',
                                fontSize: '18px',
                                padding: '5px 0',
                              }}
                              onClick={handleIncrement}
                            >
                              +
                            </Typography>{' '}
                          </Stack>
                        </Stack>
                      </Stack>

                      <Box className="vatTable prntMrg">
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell sx={{ fontSize: '17px' }}>Deposit</TableCell>
                                <TableCell sx={{ fontSize: '17px' }} align="right">
                                  {formatNumber(betAmount)} {showCurrency()}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>VAT {vatPercentage}%</TableCell>
                                <TableCell align="right">{formatNumber(vatAmount)} {showCurrency()}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Placed Bet</TableCell>
                                <TableCell align="right">{formatNumber(actualBetAmount)} {showCurrency()}</TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Total Odds</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                  {formatNumber(totalOdds)}
                                </TableCell>
                              </TableRow>

                              {taxAmount > 0 ? (
                                <>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="body2"> Win </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Typography variant="body2">{formatNumber(actualwining)} {showCurrency()}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="body2">- {incomeTaxPercentage}% Income Tax</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Typography variant="body2"> {formatNumber(taxAmount)} {showCurrency()} </Typography>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ) : (
                                <></>
                              )}

                              {/* Add more rows as needed */}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <Stack alignItems="center" justifyContent="center" mb={2}>
                        <Typography variant="body2" fontWeight="bold">
                          POSIBLE WIN
                        </Typography>
                        <Typography variant="h3">{formatNumber(winngAmount)} {showCurrency()}</Typography>
                        <Button onClick={() => onSubmit()} size="large" variant="contained" type="submit" disabled={placeBetDisable}>
                          PLACE BET
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </CardContent>
            </Card>
          ) : (
            <>
              <PrintBetSlip BetDetail={BetDetail} BetBarcode={BetBarcode} />
              <BetSlip BetDetail={BetDetail} handleSetContentToShow={handleSetContentToShow} BetBarcode={BetBarcode} autoPrint />
            </>
          )}
        </Stack>
      </Container>
    </>
  );
};
export default BetTicketCode;
